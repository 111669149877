import React from 'react';
import './App.css';
import {ThemeProvider} from "@mui/material";
import {createBrowserRouter, redirect, RouterProvider} from 'react-router-dom';
import defaultTheme from "../../themes/theme";
import {Dashboard} from "../../pages/Dashboard";
import Partners from "../../pages/partners/Partners";
import ProductTypes from "../../pages/product-types/ProductTypes";
import Settings from "../../pages/settings/Settings";
import Partner from "../../pages/partner/Partner";
import {Main} from "../_reusable/Main/Main";
import {Login} from "../Login/Login";
import Countries from "../../pages/Countries";
import Country from "../../pages/Country/Country";
import UseCasesTab from "../../pages/landing/UseCasesTab";
import CalculateSelfRisk from "../../pages/landing/CalculateSelfRisk";
import AboutUsTab from "../../pages/landing/AboutUsTab";
import ContactTab from "../../pages/landing/ContactTab";
import Layout from "../../pages/landing/Layout";
import HomeTab from "../../pages/landing/HomeTab";
import OnboardingTimelineTab from "../../pages/landing/OnboardingTimelineTab";
import LegalDocuments from "../../pages/landing/LegalDocuments";
import PricingPage from "../../pages/landing/PricingPage";

export function App() {
    const router = createBrowserRouter([
        {
          element: <Layout/>,
          children: [
              {
                  element: <HomeTab/>,
                  path: '/',
              },
              {
                  element: <UseCasesTab/>,
                  path: '/use-case',
              },
              {
                  element: <CalculateSelfRisk/>,
                  path: '/calculate-roi',
              },
              {
                  element: <OnboardingTimelineTab/>,
                  path: '/onboarding',
              },
              {
                  element: <PricingPage/>,
                  path: '/pricing',
              },
              {
                  element: <AboutUsTab/>,
                  path: '/about-us',
              },
              {
                  element: <ContactTab/>,
                  path: '/contact',
              },
              {
                  element: <Login/>,
                  path: '/login',
              },
              {
                  element: <LegalDocuments/>,
                  path: '/legal',
              },
          ]
        },
        {
            element: <Main/>,
            children: [
                {
                    path: '/dashboard',
                    element: <Dashboard/>
                },
                {
                    path: '/partners',
                    element: <Partners/>
                },
                {
                    path: '/partners/:partnerId',
                    element: <Partner/>
                },
                {
                    path: '/countries',
                    element: <Countries/>
                },
                {
                    path: '/countries/:countryCode',
                    element: <Country/>
                },
                {
                    path: '/products',
                    element: <ProductTypes/>
                },
                {
                    path: '/settings',
                    element: <Settings/>
                },
                {
                    path: '/country/:countryId',
                    element: <Country/>,
                }
            ],
        }
    ]);

    return (
        <React.StrictMode>
            <ThemeProvider theme={defaultTheme}>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </React.StrictMode>
    );
}
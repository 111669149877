import React from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, Button, Paper, useMediaQuery, useTheme } from '@mui/material';
import ContactsIcon from '@mui/icons-material/Contacts';
import DescriptionIcon from '@mui/icons-material/Description';
import LaunchIcon from '@mui/icons-material/Launch';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import SchoolIcon from '@mui/icons-material/School';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SupportIcon from '@mui/icons-material/Support';

const onboardingSteps = [
    {
        title: "Initial Contact & Discussion",
        description: "Introduction meeting, needs assessment, and scope definition to understand your company's specific requirements and goals.",
        icon: ContactsIcon
    },
    {
        title: "Proposal & Agreement",
        description: "Formal proposal submission, contract negotiation, and agreement signing to establish our working relationship.",
        icon: DescriptionIcon
    },
    {
        title: "Kickoff Process",
        description: "Onboarding team assignment, scheduling key milestones, and setting up access and system requirements.",
        icon: GroupsIcon
    },
    {
        title: "Information Gathering",
        description: "Collection of company documentation, account setup requirements, and review of technical specifications.",
        icon: DescriptionIcon
    },
    {
        title: "Implementation",
        description: "System configuration, integration with your existing tools, and initial testing phase to ensure everything works properly.",
        icon: SettingsIcon
    },
    {
        title: "Training",
        description: "User and administrator training sessions along with comprehensive documentation to ensure your team is fully prepared.",
        icon: SchoolIcon
    },
    {
        title: "Launch & Go-Live",
        description: "Final verification, official transition, and support handover to mark the beginning of your fully operational setup.",
        icon: LaunchIcon
    },
    {
        title: "Post-Implementation",
        description: "Regular Maintenance contract, regular check-ins, performance reviews, and identification of optimization opportunities for continuous improvement.",
        icon: SupportIcon
    }
];

export default function OnboardingTimelineTab() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Fixed for TypeScript with proper type casting
    React.useEffect(() => {
        // Type-safe way to access document elements
        const htmlElement = document.documentElement as HTMLElement;
        const bodyElement = document.body as HTMLElement;
        const rootElement = document.getElementById('root') as HTMLElement | null;

        // Array to store original styles for cleanup
        interface StoredStyle {
            element: HTMLElement;
            cssText: string;
        }

        const originalStyles: StoredStyle[] = [];

        // Store and modify HTML element
        if (htmlElement) {
            originalStyles.push({
                element: htmlElement,
                cssText: htmlElement.style.cssText
            });
            htmlElement.style.height = 'auto';
            htmlElement.style.minHeight = '100%';
            htmlElement.style.overflow = 'visible';
        }

        // Store and modify body element
        if (bodyElement) {
            originalStyles.push({
                element: bodyElement,
                cssText: bodyElement.style.cssText
            });
            bodyElement.style.height = 'auto';
            bodyElement.style.minHeight = '100%';
            bodyElement.style.overflow = 'visible';
        }

        // Store and modify root element
        if (rootElement) {
            originalStyles.push({
                element: rootElement,
                cssText: rootElement.style.cssText
            });
            rootElement.style.height = 'auto';
            rootElement.style.minHeight = '100%';
            rootElement.style.overflow = 'visible';
        }

        return () => {
            // Restore original styles
            originalStyles.forEach(item => {
                item.element.style.cssText = item.cssText;
            });
        };
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                overflow: 'visible',
                pb: 8 // Add padding at bottom
            }}
        >
            <Container maxWidth="lg" sx={{ pt: 6, overflow: 'visible' }}>
                <Typography variant="h3" component="h1" align="center" gutterBottom sx={{ fontWeight: 'bold', mb: 6 }}>
                    Company Onboarding Process
                </Typography>

                <Box sx={{ position: 'relative', py: 4 }}>
                    {/* Timeline center line - desktop version (centered) or mobile version (left-aligned) */}
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: isMobile ? '24px' : '50%',
                        width: '4px',
                        backgroundColor: '#29434e',
                        transform: isMobile ? 'none' : 'translateX(-50%)',
                        zIndex: 1
                    }} />

                    {onboardingSteps.map((step, index) => {
                        const IconComponent = step.icon;
                        const isEven = index % 2 === 0;

                        return (
                            <Grid container key={index} sx={{
                                mb: isMobile ? 8 : 5,  // Increased bottom margin for mobile
                                position: 'relative'
                            }}>
                                {/* Timeline circle node */}
                                <Box sx={{
                                    position: 'absolute',
                                    left: isMobile ? '24px' : '50%',
                                    top: isMobile ? 24 : 24,
                                    transform: isMobile ? 'none' : 'translateX(-50%)',
                                    width: 50,
                                    height: 50,
                                    borderRadius: '50%',
                                    backgroundColor: '#fff',
                                    border: '4px solid #29434e',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 2
                                }}>
                                    <IconComponent sx={{ color: '#29434e', fontSize: 28 }} />
                                </Box>

                                {isMobile ? (
                                    // Mobile layout - all items on the right side with increased spacing
                                    <Grid item xs={12} sx={{ pl: 8, ml: 2 }}>
                                        <Paper
                                            elevation={3}
                                            sx={{
                                                p: 3,
                                                backgroundColor: 'rgba(41,67,78,0.09)',
                                                height: '100%',
                                                mb: 2,  // Added margin at bottom of each paper
                                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'  // Enhanced shadow for better separation
                                            }}
                                        >
                                            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                                                {step.title}
                                            </Typography>
                                            <Typography variant="body1">
                                                {step.description}
                                            </Typography>
                                        </Paper>
                                        {/* Added additional spacing div after each item */}
                                        <Box sx={{ height: 24 }} />
                                    </Grid>
                                ) : (
                                    // Desktop layout - alternating sides
                                    isEven ? (
                                        <>
                                            <Grid item xs={5.5} sx={{ pr: 4, textAlign: 'right' }}>
                                                <Paper elevation={3} sx={{ p: 3, backgroundColor: 'rgba(41,67,78,0.09)', height: '100%' }}>
                                                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                                                        {step.title}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {step.description}
                                                    </Typography>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={6.5}></Grid> {/* Empty space on right */}
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={6.5}></Grid> {/* Empty space on left */}
                                            <Grid item xs={5.5} sx={{ pl: 4 }}>
                                                <Paper elevation={3} sx={{ p: 3, backgroundColor: 'rgba(41,67,78,0.09)', height: '100%' }}>
                                                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                                                        {step.title}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {step.description}
                                                    </Typography>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )
                                )}
                            </Grid>
                        );
                    })}
                </Box>

                <Box sx={{ mt: 8, textAlign: 'center' }}>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{
                            mt: 2,
                            backgroundColor: '#29434e',
                            '&:hover': {
                                backgroundColor: '#455a64',
                            }
                        }}
                        component="a"
                        href="https://valbilon.notion.site/1b180f74b39280d6b787e3e74e4c9999?pvs=105"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Schedule an onboarding meeting
                    </Button>
                </Box>
            </Container>
        </Box>
    );
}
// MethodologyModal.jsx
import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Card,
    Grid,
    IconButton,
    Fade,
    Chip,
    MobileStepper,
    useMediaQuery,
    useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ecosystem_factor from '../../assets/images/ecosystem-factors.png'

// @ts-ignore
const MethodologyModal = ({ open, handleClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const steps = [
        {
            label: "Ecosystem and influencing factors of issue management regarding product safety/deception",
            icon: "1",
            content: (
                <>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}>
                        <Box
                            component="img"
                            src={ecosystem_factor}
                            alt="Ecosystem and influencing factors diagram"
                            sx={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                                borderRadius: 2,
                            }}
                        />
                    </Box>
                </>
            )
        },
        {
            label: "Data Related to the External Ecosystem of the Supplier",
            icon: "2",
            content: (
                <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>External Ecosystem Analysis</Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        We analyze the broader context in which suppliers operate to identify potential systemic risks.
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>Key Inputs:</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                        {["Governance indicators", "Power relationship in a country", "Quality of legislation", "Economic dynamics"].map((item, i) => (
                            <Chip
                                key={i}
                                label={item}
                                sx={{
                                    bgcolor: 'rgba(41, 67, 78, 0.1)',
                                    color: '#29434e',
                                    mb: 1
                                }}
                                icon={<CheckCircleOutlineIcon fontSize="small" />}
                            />
                        ))}
                    </Box>
                </>
            )
        },
        {
            label: "The Product Risk",
            icon: "3",
            content: (
                <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Product Risk Assessment</Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        Comprehensive analysis of inherent and acquired risks related to products in your supply chain.
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>Risk Evaluation Methods:</Typography>
                    <Box sx={{ pl: 2, mb: 2 }}>
                        {["Hazard Analysis & Critical Control Points", "Product and risk categories from industry databases",
                            "Internal data: incidents, recalls, complaints", "External sources: Regulatory databases, Industry Trend Monitors"]
                            .map((item, idx) => (
                                <Box key={idx} sx={{ mb: 2, display: 'flex', alignItems: 'flex-start' }}>
                                    <Box component="span" sx={{
                                        bgcolor: '#29434e',
                                        color: 'white',
                                        minWidth: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mr: 1.5,
                                        flexShrink: 0
                                    }}>{idx + 1}</Box>
                                    <Typography variant="body2">
                                        {item}
                                    </Typography>
                                </Box>
                            ))}
                    </Box>
                </>
            )
        },
        {
            label: "The Legislation's Gap",
            icon: "4",
            content: (
                <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Regulatory Compliance Analysis</Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        Identification of potential compliance issues between different jurisdictions.
                    </Typography>
                    <Box sx={{ p: 2, bgcolor: 'rgba(41, 67, 78, 0.05)', borderRadius: 2, mb: 3 }}>
                        <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                            Our system performs a comprehensive gap analysis between country of origin and country
                            of destination for each product, identifying potential regulatory conflicts or compliance
                            challenges.
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            p: 2,
                            bgcolor: 'rgba(41, 67, 78, 0.1)',
                            borderRadius: 1,
                            width: { xs: '100%', sm: 'auto' }
                        }}>
                            <Typography variant="subtitle2">Origin Country</Typography>
                            <Typography variant="body2">Regulatory Framework</Typography>
                        </Box>
                        <ArrowForwardIcon sx={{
                            color: '#29434e',
                            transform: { xs: 'rotate(90deg)', sm: 'none' },
                            my: { xs: 1, sm: 0 }
                        }} />
                        <Box sx={{
                            textAlign: 'center',
                            p: 2,
                            bgcolor: 'rgba(41, 67, 78, 0.1)',
                            borderRadius: 1,
                            width: { xs: '100%', sm: 'auto' }
                        }}>
                            <Typography variant="subtitle2">Destination Country</Typography>
                            <Typography variant="body2">Regulatory Framework</Typography>
                        </Box>
                    </Box>
                </>
            )
        },
        {
            label: "Internal Data Related to the Supplier and Supplier History",
            icon: "5",
            content: (
                <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Supplier Profile Analysis</Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        In-depth assessment of supplier reliability based on historical performance and structural
                        attributes.
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>Examples Of Key Evaluation Criteria:</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ p: 2, bgcolor: 'rgba(41, 67, 78, 0.05)', borderRadius: 1, mb: { xs: 0, sm: 0 }, height: '100%' }}>
                                <Typography variant="subtitle2" sx={{ color: '#29434e', mb: 1 }}>Structural Factors</Typography>
                                <Typography variant="body2">• ESG metrics</Typography>
                                <Typography variant="body2">• Legal structure</Typography>
                                <Typography variant="body2">• Years in business</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ p: 2, bgcolor: 'rgba(41, 67, 78, 0.05)', borderRadius: 1, mt: { xs: 2, sm: 0 }, height: '100%' }}>
                                <Typography variant="subtitle2" sx={{ color: '#29434e', mb: 1 }}>Historical Performance</Typography>
                                <Typography variant="body2">• Quality deviations</Typography>
                                <Typography variant="body2">• Delivery reliability</Typography>
                                <Typography variant="body2">• Turnover evolution</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )
        },
        {
            label: "Take Measures According to the Company Policy",
            icon: "6",
            content: (
                <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Risk Mitigation Strategy</Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        Implementation of appropriate measures based on risk profiles and company policies.
                    </Typography>
                    <Box sx={{ p: 2, bgcolor: 'rgba(41, 67, 78, 0.05)', borderRadius: 2, mb: 2 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1, color: '#29434e' }}>Available Measures:</Typography>
                        <Grid container spacing={2}>
                            {["Audits", "Risk Intelligence", "Product analysis", "Exit", "Trust building"].map((measure, i) => (
                                <Grid item xs={6} sm={4} key={i}>
                                    <Box sx={{
                                        p: 1.5,
                                        bgcolor: '#29434e',
                                        color: 'white',
                                        borderRadius: 1,
                                        textAlign: 'center'
                                    }}>
                                        <Typography variant="body2">{measure}</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </>
            )
        },
        {
            label: "Audit",
            icon: "7",
            content: (
                <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Audit Implementation</Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        Structured evaluation of supplier processes, facilities, and compliance.
                    </Typography>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1, color: '#29434e' }}>Key Tools:</Typography>
                        <Box sx={{
                            p: 2,
                            border: '1px solid rgba(41, 67, 78, 0.2)',
                            borderRadius: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                <Box sx={{
                                    minWidth: 40,
                                    height: 40,
                                    borderRadius: '50%',
                                    bgcolor: '#29434e',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    mr: 2,
                                    flexShrink: 0
                                }}>1</Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="body2">Targeted check lists specific to supplier type and risk profile</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                <Box sx={{
                                    minWidth: 40,
                                    height: 40,
                                    borderRadius: '50%',
                                    bgcolor: '#29434e',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    mr: 2,
                                    flexShrink: 0
                                }}>2</Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="body2">Well-trained auditors with industry-specific expertise</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ p: 2, bgcolor: '#29434e', color: 'white', borderRadius: 2 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>Output:</Typography>
                        <Typography variant="body2">
                            Comprehensive audit reports with detailed compliance scoring and recommendations for
                            improvement
                        </Typography>
                    </Box>
                </>
            )
        },
        {
            label: "Control or Support Measures",
            icon: "8",
            content: (
                <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Ongoing Monitoring & Support</Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        Continuous assessment and appropriate intervention strategies based on risk profiles.
                    </Typography>
                    <Box
                        sx={{
                            p: 3,
                            bgcolor: 'rgba(41, 67, 78, 0.05)',
                            borderRadius: 2,
                            mb: 2
                        }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 2, color: '#29434e' }}>
                            Available Control & Support Measures:
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Box sx={{
                                    p: 2,
                                    bgcolor: '#29434e',
                                    color: 'white',
                                    borderRadius: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>Risk Intelligence</Typography>
                                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                        Continuous monitoring for early warning signals
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{
                                    p: 2,
                                    bgcolor: '#29434e',
                                    color: 'white',
                                    borderRadius: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: { xs: 2, md: 0 }
                                }}>
                                    <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>Product Analysis</Typography>
                                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                        Targeted testing and verification procedures
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{
                                    p: 2,
                                    bgcolor: '#29434e',
                                    color: 'white',
                                    borderRadius: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: { xs: 2, md: 0 }
                                }}>
                                    <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>Exit Strategy</Typography>
                                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                        Disengagement protocols for high-risk suppliers
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )
        }
    ];

    const handleNext = () => {
        setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
    };

    const handleBack = () => {
        setActiveStep((prev) => Math.max(prev - 1, 0));
    };

    const handleStepClick = (index: React.SetStateAction<number>) => {
        setActiveStep(index);
    };

    // Mobile specific layout
    const renderMobileView = () => (
        <Box sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            overflow: 'hidden'
        }}>
            {/* Header */}
            <Box sx={{
                p: 2,
                bgcolor: '#29434e',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                zIndex: 10
            }}>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                    Risk Detection Methodology
                </Typography>
                <IconButton onClick={handleClose} sx={{ color: 'white' }}>
                    <CloseIcon />
                </IconButton>
            </Box>

            {/* Step title & indicator */}
            <Box sx={{
                p: 2,
                bgcolor: 'rgba(41, 67, 78, 0.05)',
                zIndex: 9,
                flexShrink: 0
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Box sx={{
                        width: 28,
                        height: 28,
                        borderRadius: '50%',
                        bgcolor: '#29434e',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        mr: 1.5,
                        flexShrink: 0
                    }}>
                        {steps[activeStep].icon}
                    </Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#29434e' }}>
                        {steps[activeStep].label}
                    </Typography>
                </Box>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Step {activeStep + 1} of {steps.length}
                </Typography>
            </Box>

            {/* Content - This is the scrollable area */}
            <Box sx={{
                flex: 1,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                p: 2
            }}>
                <Card sx={{
                    p: 2,
                    boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'min-content',
                    height: 'auto'
                }}>
                    {steps[activeStep].content}
                </Card>
            </Box>

            {/* Bottom navigation */}
            <MobileStepper
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                sx={{
                    bgcolor: 'white',
                    borderTop: '1px solid rgba(0,0,0,0.1)',
                    '& .MuiMobileStepper-dot': {
                        bgcolor: 'rgba(41, 67, 78, 0.3)',
                    },
                    '& .MuiMobileStepper-dotActive': {
                        bgcolor: '#29434e',
                    },
                    flexShrink: 0,
                    zIndex: 10
                }}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === steps.length - 1}
                        sx={{
                            color: '#29434e',
                            '&.Mui-disabled': {
                                color: 'rgba(0, 0, 0, 0.26)'
                            }
                        }}
                    >
                        Next
                        <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        sx={{
                            color: '#29434e',
                            '&.Mui-disabled': {
                                color: 'rgba(0, 0, 0, 0.26)'
                            }
                        }}
                    >
                        <KeyboardArrowLeft />
                        Back
                    </Button>
                }
            />
        </Box>
    );

    // Desktop layout
    const renderDesktopView = () => (
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { sm: '90%', md: '85%', lg: '80%' },
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
        }}>
            {/* Header */}
            <Box sx={{
                p: 2,
                bgcolor: '#29434e',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <Typography id="methodology-modal-title" variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    Our Risk Detection Methodology
                </Typography>
                <IconButton onClick={handleClose} sx={{ color: 'white' }}>
                    <CloseIcon />
                </IconButton>
            </Box>

            {/* Content */}
            <Box sx={{ display: 'flex', height: { sm: '500px', md: '600px' } }}>
                {/* Step navigation sidebar */}
                <Box sx={{
                    width: { sm: '220px', md: '280px' },
                    bgcolor: '#f5f5f5',
                    overflowY: 'auto',
                }}>
                    {steps.map((step, index) => (
                        <Box
                            key={index}
                            onClick={() => handleStepClick(index)}
                            sx={{
                                p: 2,
                                cursor: 'pointer',
                                bgcolor: activeStep === index ? 'rgba(41, 67, 78, 0.1)' : 'transparent',
                                borderLeft: activeStep === index ? '4px solid #29434e' : '4px solid transparent',
                                '&:hover': {
                                    bgcolor: activeStep === index ? 'rgba(41, 67, 78, 0.1)' : 'rgba(41, 67, 78, 0.05)',
                                }
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                <Box sx={{
                                    minWidth: 28,
                                    height: 28,
                                    borderRadius: '50%',
                                    bgcolor: activeStep === index ? '#29434e' : 'rgba(41, 67, 78, 0.2)',
                                    color: activeStep === index ? 'white' : '#29434e',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 'bold',
                                    mr: 1.5,
                                    flexShrink: 0
                                }}>
                                    {step.icon}
                                </Box>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: activeStep === index ? 'bold' : 'normal',
                                        color: activeStep === index ? '#29434e' : 'text.primary',
                                        lineHeight: 1.3,
                                        mt: 0.3
                                    }}
                                >
                                    {step.label}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>

                {/* Main content area */}
                <Box sx={{
                    flex: 1,
                    p: 3,
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {/* Step content */}
                    <Card sx={{
                        flex: 1,
                        p: 3,
                        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        {steps[activeStep].content}
                    </Card>

                    {/* Navigation controls */}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 3
                    }}>
                        <Button
                            onClick={handleBack}
                            disabled={activeStep === 0}
                            startIcon={<ArrowBackIcon />}
                            sx={{
                                color: '#29434e',
                                '&.Mui-disabled': {
                                    color: 'rgba(0, 0, 0, 0.26)'
                                }
                            }}
                        >
                            Previous
                        </Button>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                onClick={handleNext}
                                disabled={activeStep === steps.length - 1}
                                endIcon={<ArrowForwardIcon />}
                                variant="contained"
                                sx={{
                                    backgroundColor: '#29434e',
                                    '&:hover': {
                                        backgroundColor: '#455a64',
                                    },
                                    '&.Mui-disabled': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.12)'
                                    }
                                }}
                            >
                                Next
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            aria-labelledby="methodology-modal-title"
        >
            <Fade in={open}>
                {isMobile ? renderMobileView() : renderDesktopView()}
            </Fade>
        </Modal>
    );
};

export default MethodologyModal;
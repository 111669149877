import React, { useEffect, useState } from 'react';
import {
    Container, Typography, Button, Grid, Box,
    Card, CardContent
} from '@mui/material';
import { styled } from '@mui/system';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SecurityIcon from '@mui/icons-material/Security';
import BusinessIcon from '@mui/icons-material/Business';
import InsightsIcon from '@mui/icons-material/Insights';
import landingImage from '../../assets/images/landing-risk.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MethodologyModal from './MethodologyModal'; // Import the modal component



const HeroSection = styled(Box)(({ theme }) => ({
    minHeight: '95vh', // Changed from 60vh to 100vh to take full screen height
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: 'linear-gradient(135deg, #455a64 0%, #29434e 100%)',
    color: 'white',
    padding: theme.spacing(4),
    paddingTop: `calc(${theme.spacing(4)} + 64px)`, // Add navbar height to padding-top
    position: 'relative',
    zIndex: 0,
    boxSizing: 'border-box', // Ensure padding is included in height calculation
}));

const FeatureCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-10px)',
        boxShadow: '0 12px 20px rgba(0,0,0,0.2)',
    },
}));

const features = [
    {
        icon: <BusinessIcon fontSize="large" sx={{ color: '#29434e', mb: 2 }} />,
        title: "Information Gap Analysis",
        description: "Identify missing information that could impact your business decisions and reduce the risk of concealed data."
    },
    {
        icon: <AssessmentIcon fontSize="large" sx={{ color: '#29434e', mb: 2 }} />,
        title: "Risk Assessment",
        description: "Evaluate potential risks across your business network with advanced scoring algorithms that detect information concealment."
    },
    {
        icon: <InsightsIcon fontSize="large" sx={{ color: '#29434e', mb: 2 }} />,
        title: "Multi-Grade Evaluation",
        description: "Get comprehensive risk scores across multiple dimensions to ensure complete visibility into your network."
    },
    {
        icon: <SecurityIcon fontSize="large" sx={{ color: '#29434e', mb: 2 }} />,
        title: "Proactive Management",
        description: "Stay ahead of potential disruptions with heat maps highlighting risk areas among customers, suppliers, and products."
    },
];

const ScrollDownArrow = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: 1,
    transition: 'opacity 0.3s ease',
    animation: 'bounce 2s infinite',
    cursor: 'pointer',
    '@keyframes bounce': {
        '0%, 20%, 50%, 80%, 100%': {
            transform: 'translateY(0) translateX(-50%)',
        },
        '40%': {
            transform: 'translateY(-20px) translateX(-50%)',
        },
        '60%': {
            transform: 'translateY(-10px) translateX(-50%)',
        },
    },
}));

interface HomeTabProps {
    isMobile?: boolean;
}

export default function HomeTab({ isMobile }: HomeTabProps) {
    const [showArrow, setShowArrow] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setShowArrow(false);
            } else {
                setShowArrow(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToFeatures = () => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth'
        });
    };

    // More balanced approach to fixing scrolling
    useEffect(() => {
        // Only add minimal fixes to enable scrolling without creating excessive space
        const htmlElement = document.documentElement as HTMLElement;
        const bodyElement = document.body as HTMLElement;

        // Store original overflow values
        const originalHtmlOverflow = htmlElement.style.overflow;
        const originalBodyOverflow = bodyElement.style.overflow;

        // Only set overflow to auto, not changing height properties
        htmlElement.style.overflow = 'auto';
        bodyElement.style.overflow = 'auto';

        return () => {
            // Restore original overflow values
            htmlElement.style.overflow = originalHtmlOverflow;
            bodyElement.style.overflow = originalBodyOverflow;
        };
    }, []);

    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            {/* Add top padding to account for the navbar */}
            <HeroSection sx={{ pt: { xs: 4, sm: 4, md: 0 } }}>
                <Container>
                    <Grid container spacing={6} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h2"
                                component="h1"
                                gutterBottom
                                sx={{ fontWeight: 'bold', fontSize: { xs: '2.5rem', md: '3.5rem' } }}
                            >
                                Revealing Hidden Risks in Your Business Network
                            </Typography>
                            <Typography variant="h5" paragraph sx={{ mb: 4, opacity: 0.9 }}>
                                Our proactive risk management platform exposes concealed information and provides comprehensive heat maps to protect your business.
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    component="a"
                                    href="https://valbilon.notion.site/1b180f74b39280d6b787e3e74e4c9999?pvs=105"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        backgroundColor: 'white',
                                        color: '#29434e',
                                        px: 4,
                                        py: 1.5,
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5',
                                        }
                                    }}
                                >
                                    Request Demo
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Box
                                component="img"
                                src={landingImage}
                                alt="Risk Heat Map Preview"
                                sx={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    boxShadow: '0 20px 40px rgba(0,0,0,0.3)',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
                {/* Add the scroll down arrow */}
                {showArrow && (
                    <ScrollDownArrow onClick={scrollToFeatures}>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            Scroll Down
                        </Typography>
                        <KeyboardArrowDownIcon fontSize="large" />
                    </ScrollDownArrow>
                )}
            </HeroSection>

            <Box sx={{ bgcolor: '#f0f3f5', py: 8 }}>
                <Container maxWidth="lg">
                    <Typography
                        variant="h3"
                        component="h2"
                        align="center"
                        gutterBottom
                        sx={{ fontWeight: 'bold', mb: 4 }}
                    >
                        Preempt Disaster: Expose Silent Threats in Your Company
                    </Typography>

                    {/* First paragraph - shorter overview */}
                    <Box sx={{ mb: 5, maxWidth: '800px', mx: 'auto', textAlign: 'center' }}>
                        <Typography variant="h6" paragraph sx={{ lineHeight: 1.7 }}>
                            No organization, however robust, is immune to collapse—whether from external shocks or internal decay. Our research on 500+ real-world crises reveal that information communication deficits fuel disasters. Our tools, forged from decades of crisis research, expose hidden vulnerabilities in your network's information flow, transforming concealed risks into actionable foresight to avert preventable disruptions.
                        </Typography>
                    </Box>

                    {/* Second paragraph - in-depth with better formatting */}
                    <Box
                        sx={{
                            bgcolor: 'white',
                            p: 4,
                            borderRadius: '8px',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                            maxWidth: '1000px',
                            mx: 'auto'
                        }}
                    >
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#29434e', mb: 3 }}>
                            The Science Behind Our Approach
                        </Typography>

                        <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, mb: 2 }}>
                            Most organizational disasters—from operational failures to full-blown crises—stem not from unforeseeable events, but from systemic deficits in how critical information flows <i>within</i> institutions. Whether due to cultural silos, hierarchical barriers, or misaligned incentives, concealed or distorted information often blinds leaders to escalating risks while stifling frontline warnings.
                        </Typography>

                        <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, mb: 2 }}>
                            Our risk analysis tools are grounded in decades of research into 500+ real-world crises (as documented in <i>Specific Features of Risk Management</i>, 2019, and <i>Man-made Catastrophes</i>, 2016). We diagnose communication fractures by mapping how data travels—or stalls—across your business network.
                        </Typography>

                        <Typography variant="body1" paragraph sx={{ lineHeight: 1.8 }}>
                            By integrating insights from <i>Don't Tell the Boss!</i> (2022) on institutional secrecy and <i>Averting Disaster Before It Strikes</i> (2023) on proactive mitigation, we expose hidden vulnerabilities, align information-sharing mechanisms with organizational goals, and equip decision-makers to intercept risks before they metastasize. The result? A culture of efficient communications that transforms concealed data into actionable foresight, empowering stakeholders at all levels to avert disruptions rooted in information asymmetry.
                        </Typography>
                    </Box>
                </Container>
            </Box>

            <Container sx={{ py: 8 }}>
                <Typography
                    variant="h3"
                    component="h2"
                    align="center"
                    gutterBottom
                    sx={{ fontWeight: 'bold', mb: 2 }}
                >
                    What We Do
                </Typography>

                <Typography variant="h6" align="center" sx={{ mb: 6, maxWidth: '800px', mx: 'auto' }}>
                    We provide comprehensive risk analysis tools that reveal concealed information in your business network,
                    helping you make informed decisions and prevent costly disruptions.
                </Typography>

                <Grid container spacing={4}>
                    {features.map((feature, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <FeatureCard>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', flexGrow: 1 }}>
                                    {feature.icon}
                                    <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
                                        {feature.title}
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        {feature.description}
                                    </Typography>
                                </CardContent>
                            </FeatureCard>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Box sx={{ bgcolor: '#f5f5f5', py: 8 }}>
                <Container>
                    <Grid container spacing={6} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h3" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
                                Our Risk Detection Methodology
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Our platform provides detailed heat maps that highlight risk levels across your customers, suppliers, and products. We analyze both existing and missing information to identify potential areas of concealment.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Each product and relationship is assigned risk ratings across multiple dimensions, giving you complete visibility into your business network's vulnerabilities.
                            </Typography>
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                onClick={handleOpenModal} // Add onClick handler
                                sx={{
                                    mt: 2,
                                    backgroundColor: '#29434e',
                                    '&:hover': {
                                        backgroundColor: '#455a64',
                                    }
                                }}
                            >
                                Explore Our Methodology
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    bgcolor: 'white',
                                    p: 4,
                                    borderRadius: '8px',
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                                }}
                            >
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#29434e' }}>
                                    Our Risk Assessment Framework
                                </Typography>
                                <Box sx={{ mt: 3 }}>
                                    {[
                                        "Customer & Supplier Heat Mapping",
                                        "Information Gap Detection",
                                        "Product Risk Annotation",
                                        "Multi-Dimensional Risk Scoring",
                                        "Information Concealment Prevention"
                                    ].map((item, index) => (
                                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Box
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                    borderRadius: '50%',
                                                    bgcolor: '#455a64',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    mr: 2
                                                }}
                                            >
                                                {index + 1}
                                            </Box>
                                            <Typography>{item}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Include the Modal component */}
            <MethodologyModal open={modalOpen} handleClose={handleCloseModal} />
        </>
    );
}
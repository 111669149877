import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Container, Typography, Box, Tabs, Tab, Paper, Accordion, AccordionSummary,
    AccordionDetails, Divider, List, ListItem, ListItemText, Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import GavelIcon from '@mui/icons-material/Gavel';
import WarningIcon from '@mui/icons-material/Warning';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

// Tab Panel Component
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`legal-tabpanel-${index}`}
            aria-labelledby={`legal-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: { xs: 2, md: 3 } }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

// A11y props for tabs
function a11yProps(index: number) {
    return {
        id: `legal-tab-${index}`,
        'aria-controls': `legal-tabpanel-${index}`,
    };
}

export default function LegalDocuments() {
    const location = useLocation();
    const [value, setValue] = useState(0);
    const [expanded, setExpanded] = useState<string | false>('panel1');
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

    // Refs for the content to be exported
    const privacyPolicyRef = useRef<HTMLDivElement>(null);
    const termsOfServiceRef = useRef<HTMLDivElement>(null);
    const disclaimerRef = useRef<HTMLDivElement>(null);

    // Handle URL query parameters to select the correct tab
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabParam = searchParams.get('tab');

        if (tabParam === 'privacy') {
            setValue(0);
        } else if (tabParam === 'terms') {
            setValue(1);
        } else if (tabParam === 'disclaimer') {
            setValue(2);
        }
    }, [location]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Common styling for section headings
    const sectionHeadingStyle = {
        color: '#29434e',
        fontWeight: 'bold',
        my: 3,
        pt: 2
    };

    // Function to generate PDF from the current tab content
    const generatePDF = async () => {
        setIsGeneratingPDF(true);

        try {
            // Select the appropriate ref based on the current tab
            let contentRef;
            let fileName;

            if (value === 0) {
                contentRef = privacyPolicyRef;
                fileName = "Albatrisk-Privacy-Policy.pdf";
            } else if (value === 1) {
                contentRef = termsOfServiceRef;
                fileName = "Albatrisk-Terms-of-Service.pdf";
            } else {
                contentRef = disclaimerRef;
                fileName = "Albatrisk-Disclaimer.pdf";
            }

            if (!contentRef.current) {
                throw new Error("Content reference is not available");
            }

            // Make all accordions expanded for the PDF
            const originalExpanded = expanded;
            setExpanded('all-panels');

            // Wait for the DOM to update
            await new Promise(resolve => setTimeout(resolve, 500));

            // Create canvas from the content
            const canvas = await html2canvas(contentRef.current, {
                scale: 2, // Higher scale for better quality
                useCORS: true,
                logging: false,
                windowWidth: 1200, // Fixed width to ensure consistent layout
                onclone: (document: { querySelectorAll: (arg0: string) => any; }) => {
                    // Optionally manipulate cloned elements if needed
                }
            });

            // Create PDF
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 width in mm
            const imgHeight = canvas.height * imgWidth / canvas.width;
            const pageHeight = 297; // A4 height in mm
            let heightLeft = imgHeight;
            let position = 0;

            // Add a header banner and title
            pdf.setFillColor(41, 67, 78);
            pdf.rect(0, 0, 210, 25, 'F');
            pdf.setTextColor(255, 255, 255);
            pdf.setFont('helvetica', 'bold');
            pdf.setFontSize(16);

            let title;
            if (value === 0) title = "Privacy Policy";
            else if (value === 1) title = "Terms of Service";
            else title = "Disclaimer";

            pdf.text(`Albatrisk - ${title}`, 105, 15, { align: 'center' });

            // Add footer with page numbers
            const addFooter = (pageNumber: number) => {
                pdf.setFillColor(240, 240, 240);
                pdf.rect(0, 287, 210, 10, 'F');
                pdf.setTextColor(100, 100, 100);
                pdf.setFontSize(8);
                pdf.text(`© 2025 Albatrisk. All rights reserved. | Page ${pageNumber}`, 105, 293, { align: 'center' });
            };

            // Add the canvas as an image on the first page
            pdf.addImage(canvas, 'PNG', 0, 30, imgWidth, imgHeight);
            heightLeft -= (pageHeight - 30);
            addFooter(1);

            // Add additional pages if the content is longer than one page
            let pageNumber = 1;
            while (heightLeft > 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pageNumber++;
                pdf.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                addFooter(pageNumber);
            }

            // Download the PDF
            pdf.save(fileName);

            // Reset the accordions state
            setExpanded(originalExpanded);

        } catch (error) {
            console.error("Error generating PDF:", error);
            alert("There was an error generating the PDF. Please try again.");
        } finally {
            setIsGeneratingPDF(false);
        }
    };

    useEffect(() => {
        // Minimal fix for scrolling
        const htmlElement = document.documentElement as HTMLElement;
        const bodyElement = document.body as HTMLElement;

        // Store original overflow
        const originalHtmlOverflow = htmlElement.style.overflow;
        const originalBodyOverflow = bodyElement.style.overflow;

        htmlElement.style.overflow = 'auto';
        bodyElement.style.overflow = 'auto';

        return () => {
            htmlElement.style.overflow = originalHtmlOverflow;
            bodyElement.style.overflow = originalBodyOverflow;
        };
    }, []);

    // Special rendering for PDF export - all accordions expanded
    const isPDFMode = expanded === 'all-panels';

    return (
        <Box
            sx={{
                width: '100%',
                py: 6,
                bgcolor: '#f5f7f8'
            }}
        >
            <Container maxWidth="lg">
                <Typography variant="h3" component="h1" align="center" gutterBottom sx={{ fontWeight: 'bold', mb: 4 }}>
                    Legal Information
                </Typography>

                <Paper
                    elevation={2}
                    sx={{
                        borderRadius: 2,
                        overflow: 'hidden',
                        mb: 8
                    }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="legal information tabs"
                            sx={{
                                bgcolor: '#29434e',
                                '& .MuiTab-root': {
                                    color: 'rgba(255,255,255,0.7)',
                                    fontWeight: 'medium',
                                    transition: 'all 0.2s ease',
                                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                    padding: { xs: '8px 10px', sm: '12px 16px' },
                                    minWidth: { xs: 'auto', sm: 120 },
                                    '&.Mui-selected': {
                                        color: 'white !important',
                                        fontWeight: 'bold',
                                        backgroundColor: 'rgba(255,255,255,0.1)'
                                    },
                                },
                                '& .MuiTabs-indicator': {
                                    backgroundColor: 'white',
                                    height: 3
                                },
                            }}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                        >
                            <Tab
                                icon={<PrivacyTipIcon sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }} />}
                                iconPosition="start"
                                label={<Box sx={{ display: { xs: 'none', sm: 'block' } }}>Privacy Policy</Box>}
                                {...a11yProps(0)}
                            />
                            <Tab
                                icon={<GavelIcon sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }} />}
                                iconPosition="start"
                                label={<Box sx={{ display: { xs: 'none', sm: 'block' } }}>Terms of Service</Box>}
                                {...a11yProps(1)}
                            />
                            <Tab
                                icon={<WarningIcon sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }} />}
                                iconPosition="start"
                                label={<Box sx={{ display: { xs: 'none', sm: 'block' } }}>Disclaimer</Box>}
                                {...a11yProps(2)}
                            />
                        </Tabs>
                    </Box>

                    {/* Privacy Policy Tab */}
                    <TabPanel value={value} index={0}>
                        <div ref={privacyPolicyRef}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                                <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
                                    Privacy Policy
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    Last updated: March 15, 2025
                                </Typography>
                            </Box>

                            <Typography paragraph>
                                At Albatrisk, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our risk management platform and services.
                            </Typography>

                            <Accordion expanded={isPDFMode || expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{ bgcolor: 'rgba(41,67,78,0.05)' }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>1. Information We Collect</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="h6" sx={sectionHeadingStyle}>1.1 Personal Information</Typography>
                                    <Typography paragraph>
                                        We may collect personal information that you voluntarily provide to us when you register for our services, express interest in obtaining information about us or our products, or otherwise contact us. The personal information we collect may include:
                                    </Typography>
                                    <List sx={{ pl: 4, listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Name, email address, and contact details" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Billing information and transaction data" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="User credentials (usernames and passwords)" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Company information for business customers" />
                                        </ListItem>
                                    </List>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>1.2 Usage Data</Typography>
                                    <Typography paragraph>
                                        We automatically collect certain information when you visit, use, or navigate our platform. This information does not reveal your specific identity but may include:
                                    </Typography>
                                    <List sx={{ pl: 4, listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Device and connection information" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Browser and usage patterns" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="IP address and geolocation data" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Features you use and the timing and frequency of your activities" />
                                        </ListItem>
                                    </List>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={isPDFMode || expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    sx={{ bgcolor: 'rgba(41,67,78,0.05)' }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>2. How We Use Your Information</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography paragraph>
                                        We use the information we collect for various business purposes, including:
                                    </Typography>
                                    <List sx={{ pl: 4, listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Providing, customizing, and maintaining our services" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Processing your transactions and managing your account" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Communicating with you about updates, security alerts, and support" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Analyzing usage patterns to improve our platform" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Detecting, preventing, and addressing technical issues or fraudulent activities" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Complying with legal obligations" />
                                        </ListItem>
                                    </List>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={isPDFMode || expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                    sx={{ bgcolor: 'rgba(41,67,78,0.05)' }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>3. Information Sharing</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography paragraph>
                                        We may share information in the following situations:
                                    </Typography>
                                    <Typography variant="h6" sx={sectionHeadingStyle}>3.1 With Service Providers</Typography>
                                    <Typography paragraph>
                                        We may share your information with third-party vendors, service providers, contractors, or agents who perform services for us and require access to such information to perform these functions.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>3.2 Business Transfers</Typography>
                                    <Typography paragraph>
                                        If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>3.3 Legal Requirements</Typography>
                                    <Typography paragraph>
                                        We may disclose your information when required to do so by law or in response to valid requests by public authorities (e.g., court or government agency).
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>3.4 With Your Consent</Typography>
                                    <Typography paragraph>
                                        We may disclose your personal information for any other purpose with your consent.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={isPDFMode || expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                    sx={{ bgcolor: 'rgba(41,67,78,0.05)' }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>4. Your Rights and Choices</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography paragraph>
                                        Depending on your location, you may have certain rights regarding your personal information:
                                    </Typography>
                                    <List sx={{ pl: 4, listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText
                                                primary="Access and Data Portability"
                                                secondary="You can request copies of your personal information."
                                            />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText
                                                primary="Correction"
                                                secondary="You can request that we correct inaccurate information."
                                            />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText
                                                primary="Deletion"
                                                secondary="You can request that we delete your personal information in certain circumstances."
                                            />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText
                                                primary="Withdraw Consent"
                                                secondary="You can withdraw consent for processing where we rely on consent."
                                            />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText
                                                primary="Object"
                                                secondary="You can object to our processing of your information."
                                            />
                                        </ListItem>
                                    </List>
                                    <Typography paragraph sx={{ mt: 2 }}>
                                        To exercise these rights, please contact us at privacy@albatrisk.com.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>

                        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                startIcon={<FileDownloadIcon />}
                                onClick={generatePDF}
                                disabled={isGeneratingPDF}
                                sx={{
                                    backgroundColor: '#29434e',
                                    '&:hover': {
                                        backgroundColor: '#1c313a',
                                    }
                                }}
                            >
                                {isGeneratingPDF ? 'Generating PDF...' : 'Download Privacy Policy PDF'}
                            </Button>
                        </Box>
                    </TabPanel>

                    {/* Terms of Service Tab */}
                    <TabPanel value={value} index={1}>
                        <div ref={termsOfServiceRef}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                                <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
                                    Terms of Service
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    Last updated: March 15, 2025
                                </Typography>
                            </Box>

                            <Typography paragraph>
                                These Terms of Service ("Terms") govern your access to and use of Albatrisk's platform, services, and applications (collectively, the "Services"). Please read these Terms carefully before using our Services.
                            </Typography>

                            <Accordion expanded={isPDFMode || expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5-content"
                                    id="panel5-header"
                                    sx={{ bgcolor: 'rgba(41,67,78,0.05)' }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>1. Acceptance of Terms</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography paragraph>
                                        By accessing or using our Services, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, you may not access or use the Services.
                                    </Typography>
                                    <Typography paragraph>
                                        We may modify these Terms at any time. If we make changes, we will provide notice through our Services or by other means. Your continued use of the Services after any such notice constitutes your acceptance of the new Terms.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={isPDFMode || expanded === 'panel6'} onChange={handleAccordionChange('panel6')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel6-content"
                                    id="panel6-header"
                                    sx={{ bgcolor: 'rgba(41,67,78,0.05)' }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>2. User Accounts</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="h6" sx={sectionHeadingStyle}>2.1 Account Creation</Typography>
                                    <Typography paragraph>
                                        To use certain features of our Services, you must create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>2.2 Account Security</Typography>
                                    <Typography paragraph>
                                        You are responsible for safeguarding your account credentials and for any activities or actions under your account. You agree to notify us immediately of any unauthorized access to or use of your account.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>2.3 Account Termination</Typography>
                                    <Typography paragraph>
                                        We reserve the right to suspend or terminate your account at our sole discretion, without notice, for conduct that we determine violates these Terms or is harmful to other users, us, or third parties, or for any other reason.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={isPDFMode || expanded === 'panel7'} onChange={handleAccordionChange('panel7')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel7-content"
                                    id="panel7-header"
                                    sx={{ bgcolor: 'rgba(41,67,78,0.05)' }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>3. Additional Corporate Terms</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography paragraph>
                                        Albatrisk offers a specialized platform that corporations can use to analyze the risks associated with their partners, suppliers, or other third parties. These corporate services include optional white-label setups, customized data agreements, and secure data storage provisions.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>3.1 Custom Agreements &amp; White-Label Solutions</Typography>
                                    <Typography paragraph>
                                        Each corporate client may sign a separate agreement governing the use of their data and the scope of Albatrisk&apos;s services. This agreement may include terms granting the corporate client a white-label version of the platform, allowing them to brand and present the services under their own identity, subject to Albatrisk&apos;s underlying infrastructure and technology.
                                    </Typography>
                                    <Typography paragraph>
                                        The terms of any custom agreement or white-label arrangement shall supplement these Terms, and in the event of a conflict, the provisions of the signed custom agreement shall prevail.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>3.2 Data Storage in Switzerland</Typography>
                                    <Typography paragraph>
                                        All data provided or generated through Albatrisk&apos;s corporate services is securely stored in Switzerland. By using our Services, you acknowledge that your data, and any third-party data you collect or process using our platform, will be processed and stored on servers located in Switzerland. Corporations are responsible for ensuring that their collection, transfer, and use of such data complies with any applicable local and international data protection regulations.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>3.3 Onboarding and User Management</Typography>
                                    <Typography paragraph>
                                        Upon signing an agreement, each corporation receives a dedicated setup for their organization. The corporation can then onboard its own internal users, consultants, or other authorized personnel to use the platform. The corporation is solely responsible for:
                                    </Typography>
                                    <List sx={{ pl: 4, listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Managing permissions, roles, and access rights for all users under its account" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Ensuring all users comply with these Terms, the Privacy Policy, and any custom agreements" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Maintaining the confidentiality of all login credentials and other security measures" />
                                        </ListItem>
                                    </List>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>3.4 Third-Party Data and Liability</Typography>
                                    <Typography paragraph>
                                        Corporations using Albatrisk to analyze risks of partners, suppliers, or other external entities must have proper authorization or legal basis to collect, store, and process any third-party data. Albatrisk disclaims liability for the content, accuracy, or legality of such third-party data. The corporate client bears full responsibility for ensuring compliance with all applicable laws, regulations, and industry guidelines concerning third-party data usage.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            {/*
                              NEW SECTION: Additional Legal Provisions (panel8)
                              Contains limitation of liability, indemnification, etc.
                            */}
                            <Accordion expanded={isPDFMode || expanded === 'panel8'} onChange={handleAccordionChange('panel8')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel8-content"
                                    id="panel8-header"
                                    sx={{ bgcolor: 'rgba(41,67,78,0.05)' }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>4. Additional Legal Provisions</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="h6" sx={sectionHeadingStyle}>4.1 As-Is / No Warranty</Typography>
                                    <Typography paragraph>
                                        The Services are provided on an “as is” and “as available” basis. Albatrisk makes no representations or warranties of any kind, express or implied, about the reliability, suitability, accuracy, or availability of the Services. Your use of the Services is solely at your own risk.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>4.2 Limitation of Liability</Typography>
                                    <Typography paragraph>
                                        To the fullest extent permitted by law, Albatrisk, its affiliates, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages—including but not limited to loss of profits, goodwill, data, or other intangible losses—arising from or relating to your use of, or inability to use, the Services, even if Albatrisk has been advised of the possibility of such damages.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>4.3 Indemnification</Typography>
                                    <Typography paragraph>
                                        You agree to defend, indemnify, and hold harmless Albatrisk, its affiliates, directors, employees, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney’s fees) arising from:
                                    </Typography>
                                    <List sx={{ pl: 4, listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Your use or misuse of the Services" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Your violation of these Terms or any applicable law or regulation" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Your violation of any third-party right, including without limitation any intellectual property, confidentiality, or privacy right" />
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', p: 0.5 }}>
                                            <ListItemText primary="Any data or content you submit to the Services" />
                                        </ListItem>
                                    </List>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>4.4 Governing Law &amp; Dispute Resolution</Typography>
                                    <Typography paragraph>
                                        These Terms and any dispute related to them or the Services will be governed by and construed in accordance with the laws of [Your Preferred Jurisdiction], without regard to its conflict of law provisions. Any claim or controversy arising out of or relating to these Terms shall be settled by binding arbitration administered by [Arbitration Body] under [Arbitration Rules], and judgment on the award may be entered in any court having jurisdiction thereof. Notwithstanding the foregoing, each party shall have the right to seek injunctive or other equitable relief in a court of competent jurisdiction for any breach of these Terms.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>4.5 Force Majeure</Typography>
                                    <Typography paragraph>
                                        Albatrisk shall not be liable or responsible for any failure or delay in performance under these Terms to the extent caused by circumstances beyond its reasonable control, including but not limited to acts of God, natural disasters, war, terrorism, civil unrest, labor shortages or disputes, utility failures, or governmental actions.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>4.6 Intellectual Property</Typography>
                                    <Typography paragraph>
                                        Albatrisk retains all rights, title, and interest in and to the Services, including all software, technology, data, and content (except for user data). You may not reproduce, modify, create derivative works from, distribute, or publicly display any portion of the Services without Albatrisk’s prior written consent.
                                    </Typography>
                                    <Typography paragraph>
                                        You represent and warrant that you have all necessary rights to submit any data or content to the Services. You hereby grant Albatrisk a non-exclusive, worldwide, royalty-free license to host, store, transfer, and process such data or content solely as required to provide the Services.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>4.7 Compliance with Laws</Typography>
                                    <Typography paragraph>
                                        You are responsible for compliance with all applicable laws, rules, and regulations in using the Services, including any local data protection or privacy laws. You shall not use the Services if such use is prohibited by applicable law or regulation, or to facilitate the violation of any law or regulation.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>4.8 Data Processing Agreement (DPA)</Typography>
                                    <Typography paragraph>
                                        Where Albatrisk processes personal data on behalf of corporate clients, the parties may enter into a separate Data Processing Agreement ("DPA") governing such processing, in compliance with the GDPR or other relevant data protection laws. The DPA shall supplement these Terms.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>4.9 Amendments &amp; Waivers</Typography>
                                    <Typography paragraph>
                                        These Terms may be modified by Albatrisk from time to time. Any amendment will be effective upon posting the updated Terms. Failure by Albatrisk to enforce any provision shall not be construed as a waiver of future enforcement of that or any other provision.
                                    </Typography>

                                    <Typography variant="h6" sx={sectionHeadingStyle}>4.10 Severability &amp; Entire Agreement</Typography>
                                    <Typography paragraph>
                                        If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. These Terms (together with the Privacy Policy, Disclaimer, and any separate written agreement) constitute the entire agreement between you and Albatrisk regarding the Services, and supersede all prior agreements or understandings, whether written or oral.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>

                        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                startIcon={<FileDownloadIcon />}
                                onClick={generatePDF}
                                disabled={isGeneratingPDF}
                                sx={{
                                    backgroundColor: '#29434e',
                                    '&:hover': {
                                        backgroundColor: '#1c313a',
                                    }
                                }}
                            >
                                {isGeneratingPDF ? 'Generating PDF...' : 'Download Terms of Service PDF'}
                            </Button>
                        </Box>
                    </TabPanel>

                    {/* Disclaimer Tab */}
                    <TabPanel value={value} index={2}>
                        <div ref={disclaimerRef}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                                <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
                                    Disclaimer
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    Last updated: March 15, 2025
                                </Typography>
                            </Box>

                            <Typography paragraph>
                                This disclaimer applies to all services provided by Albatrisk, including our risk management platform, consulting services, and all related content, data, and analytics.
                            </Typography>

                            <Box sx={{
                                p: 3,
                                mb: 4,
                                bgcolor: 'rgba(41,67,78,0.09)',
                                borderRadius: 2,
                                borderLeft: '4px solid #29434e'
                            }}>
                                <Typography variant="body1" paragraph sx={{ fontWeight: 'medium' }}>
                                    <WarningIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#29434e' }} />
                                    The information provided by Albatrisk is for general informational and risk assessment purposes only. While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of our platform or the information contained therein.
                                </Typography>
                            </Box>

                            <Divider sx={{ my: 4 }} />

                            <Typography variant="h5" sx={sectionHeadingStyle}>
                                No Guaranteed Outcomes
                            </Typography>
                            <Typography paragraph>
                                Our risk assessment and management tools are designed to help you identify potential risks, but they do not guarantee the prevention of all risks or adverse outcomes. The effectiveness of our platform depends on many factors, including the accuracy of input data, changing market conditions, and unforeseen circumstances.
                            </Typography>
                        </div>

                        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                startIcon={<FileDownloadIcon />}
                                onClick={generatePDF}
                                disabled={isGeneratingPDF}
                                sx={{
                                    backgroundColor: '#29434e',
                                    '&:hover': {
                                        backgroundColor: '#1c313a',
                                    }
                                }}
                            >
                                {isGeneratingPDF ? 'Generating PDF...' : 'Download Disclaimer PDF'}
                            </Button>
                        </Box>
                    </TabPanel>
                </Paper>
            </Container>
        </Box>
    );
}
import React, { useState } from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, Button, TextField, Slider, Paper, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WarningIcon from '@mui/icons-material/Warning';
import CalculateIcon from '@mui/icons-material/Calculate';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {Link} from "react-router-dom";

const riskTypes = [
    {
        name: "Supply Chain Disruption",
        description: "A key supplier failing, causing production delays and emergency sourcing costs",
        defaultCost: 5000000,
        defaultProbability: 10,
        defaultReduction: 78 // Hardcoded around 80%
    },
    {
        name: "Regulatory Fine",
        description: "Penalties for compliance violations due to inadequate due diligence",
        defaultCost: 2000000,
        defaultProbability: 5,
        defaultReduction: 82 // Hardcoded around 80%
    },
    {
        name: "Cybersecurity Breach",
        description: "Data breach through compromised vendors or partners",
        defaultCost: 4000000,
        defaultProbability: 15,
        defaultReduction: 80 // Hardcoded around 80%
    }
];

export default function CalculateSelfRisk() {
    // ROI calculator state
    const [riskData, setRiskData] = useState(riskTypes.map(risk => ({
        cost: risk.defaultCost,
        probability: risk.defaultProbability,
        reduction: risk.defaultReduction
    })));

    const [platformCost, setPlatformCost] = useState(100000);

    // Calculate ROI metrics
    const calculateROI = () => {
        let totalRiskBefore = 0;
        let totalRiskAfter = 0;

        riskData.forEach((risk, index) => {
            const expectedLossBefore = (risk.probability / 100) * risk.cost;
            const expectedLossAfter = ((risk.probability * (100 - risk.reduction)) / 10000) * risk.cost;

            totalRiskBefore += expectedLossBefore;
            totalRiskAfter += expectedLossAfter;
        });

        const annualSavings = totalRiskBefore - totalRiskAfter;
        const roi = (annualSavings / platformCost) * 100;
        const roiAmount = annualSavings - platformCost;

        return {
            totalRiskBefore,
            totalRiskAfter,
            annualSavings,
            roi,
            roiAmount
        };
    };

    const roiMetrics = calculateROI();

    // Handle input changes
    const handleCostChange = (index: number, value: string) => {
        const newRiskData = [...riskData];
        newRiskData[index].cost = Number(value);
        setRiskData(newRiskData);
    };

    const handleProbabilityChange = (index: number, value: number | number[]) => {
        const newRiskData = [...riskData];
        newRiskData[index].probability = Number(value);
        setRiskData(newRiskData);
    };

    const handlePlatformCostChange = (value: string) => {
        setPlatformCost(Number(value));
    };

    React.useEffect(() => {
        // Type-safe way to access document elements
        const htmlElement = document.documentElement;
        const bodyElement = document.body;
        const rootElement = document.getElementById('root');

        // Array to store original styles for cleanup
        const originalStyles: { element: HTMLElement; cssText: string; }[] = [];

        // Store and modify HTML element
        if (htmlElement) {
            originalStyles.push({
                element: htmlElement,
                cssText: htmlElement.style.cssText
            });
            htmlElement.style.height = 'auto';
            htmlElement.style.minHeight = '100%';
            htmlElement.style.overflow = 'visible';
        }

        // Store and modify body element
        if (bodyElement) {
            originalStyles.push({
                element: bodyElement,
                cssText: bodyElement.style.cssText
            });
            bodyElement.style.height = 'auto';
            bodyElement.style.minHeight = '100%';
            bodyElement.style.overflow = 'visible';
        }

        // Store and modify root element
        if (rootElement) {
            originalStyles.push({
                element: rootElement,
                cssText: rootElement.style.cssText
            });
            rootElement.style.height = 'auto';
            rootElement.style.minHeight = '100%';
            rootElement.style.overflow = 'visible';
        }

        return () => {
            // Restore original styles
            originalStyles.forEach(item => {
                item.element.style.cssText = item.cssText;
            });
        };
    }, []);

    // Format currency function
    const formatCurrency = (value: number | bigint) => {
        return new Intl.NumberFormat('de-CH', {
            style: 'currency',
            currency: 'CHF',
            maximumFractionDigits: 0
        }).format(value);
    };

    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                overflow: 'visible',
                pb: 8 // Add padding at bottom
            }}
        >
            <Container maxWidth="lg" sx={{ pt: 6, overflow: 'visible' }}>
                <Typography variant="h3" component="h1" align="center" gutterBottom sx={{ fontWeight: 'bold', mb: 6 }}>
                    Calculate Your Risk and Return on Investment
                </Typography>

                {/* ROI Calculator Section */}
                <Box sx={{ mt: 8, p: 4, bgcolor: 'rgba(69,90,100,0.09)', borderRadius: 2, boxShadow: '0 4px 12px rgba(0,0,0,0.08)' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                        <CalculateIcon fontSize="large" sx={{ color: '#29434e', mr: 2 }} />
                        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Calculate Your Albatrisk ROI
                        </Typography>
                    </Box>

                    <Typography variant="body1" paragraph sx={{ mb: 4 }}>
                        Use our calculator to estimate the financial impact of implementing Albatrisk for your business.
                        Enter your own estimates for potential costs and probabilities based on your organization's specific risks.
                        Not sure where to start? We've provided industry averages as default values that you can adjust.
                        Our solution typically provides around 80% risk reduction based on industry estimates.
                    </Typography>

                    <Box sx={{ mb: 4, p: 3, bgcolor: 'rgba(69,90,100,0.05)', borderRadius: 2 }}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Industry Risk Benchmarks
                        </Typography>

                        <Typography variant="body2" paragraph>
                            Organizations face significant risks across multiple domains. Consider these industry statistics when estimating your exposure:
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Supply Chain Risks</Typography>
                                <ul style={{ marginLeft: '20px', marginTop: '8px' }}>
                                    <li>
                                        <Typography variant="body2">
                                            Companies with 1,000+ suppliers face a 10-15% annual chance of significant disruption
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2">
                                            Average cost of severe disruption: 45-55% of one year's EBITDA
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Regulatory Compliance</Typography>
                                <ul style={{ marginLeft: '20px', marginTop: '8px' }}>
                                    <li>
                                        <Typography variant="body2">
                                            Average regulatory fine: CHF 2-4 million for due diligence failures
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2">
                                            5-10% of companies face penalties annually for compliance violations
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Cybersecurity Threats</Typography>
                                <ul style={{ marginLeft: '20px', marginTop: '8px' }}>
                                    <li>
                                        <Typography variant="body2">
                                            15-20% annual likelihood of a significant breach through third parties
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2">
                                            Average data breach cost: CHF 3.5-5 million per incident
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>

                        <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 2 }}>
                            Use the calculator below to estimate your specific risk exposure and how Albatrisk can help
                            reduce it. Enter your own values based on your organization's risk profile.
                        </Typography>
                    </Box>

                    <Grid container spacing={4}>
                        {/* Input Section */}
                        <Grid item xs={12} md={7}>
                            <Paper sx={{ p: 3, mb: 3 }}>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
                                    Estimate Your Risk Parameters
                                </Typography>

                                {riskTypes.map((risk, index) => (
                                    <Box key={index} sx={{ mb: 4 }}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                            {risk.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                            {risk.description}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                            <i>Enter your organization's estimated values or use our industry defaults</i>
                                        </Typography>

                                        <Grid container spacing={3} sx={{ mb: 1 }}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label="Potential Cost (CHF)"
                                                    fullWidth
                                                    value={riskData[index].cost.toLocaleString('de-CH')}
                                                    onFocus={(e) => {
                                                        // When focused, show the raw number without formatting for easier editing
                                                        e.target.value = riskData[index].cost.toString();
                                                    }}
                                                    onBlur={(e) => {
                                                        // When leaving the field, apply formatting
                                                        const value = e.target.value.replace(/[^\d]/g, '');
                                                        const numValue = value ? parseInt(value, 10) : 0;
                                                        handleCostChange(index, numValue.toString());
                                                    }}
                                                    onChange={(e) => {
                                                        // Only allow digits (strip out non-numeric characters)
                                                        const value = e.target.value.replace(/[^\d]/g, '');
                                                        const numValue = value ? parseInt(value, 10) : 0;
                                                        handleCostChange(index, numValue.toString());
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <Box component="span" sx={{ mr: 1 }}>CHF</Box>,
                                                        inputProps: {
                                                            inputMode: 'numeric',
                                                            pattern: '[0-9]*'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" gutterBottom>
                                                    Annual Probability ({riskData[index].probability}%)
                                                </Typography>
                                                <Slider
                                                    value={riskData[index].probability}
                                                    onChange={(e, value) => handleProbabilityChange(index, value)}
                                                    step={1}
                                                    min={1}
                                                    max={50}
                                                    valueLabelDisplay="auto"
                                                    sx={{
                                                        color: '#29434e', // Change slider color to match buttons
                                                        '& .MuiSlider-thumb': {
                                                            '&:hover, &.Mui-focusVisible': {
                                                                boxShadow: '0px 0px 0px 8px rgba(41, 67, 78, 0.16)'
                                                            },
                                                            '&.Mui-active': {
                                                                boxShadow: '0px 0px 0px 14px rgba(41, 67, 78, 0.16)'
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Box sx={{ mt: 1 }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Estimated Risk Reduction: <b>{riskData[index].reduction}%</b> (based on industry averages)
                                            </Typography>
                                        </Box>

                                        {index < riskTypes.length - 1 && <Divider sx={{ my: 2 }} />}
                                    </Box>
                                ))}

                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
                                        Annual Albatrisk Platform Cost
                                    </Typography>

                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        How many suppliers do you have?
                                    </Typography>

                                    <Grid container spacing={2} sx={{ mb: 3 }}>
                                        <Grid item xs={12} sm={4}>
                                            <Box
                                                sx={{
                                                    border: '1px solid',
                                                    borderColor: platformCost === 15000 ? '#29434e' : 'grey.300',
                                                    borderRadius: 1,
                                                    p: 2,
                                                    cursor: 'pointer',
                                                    bgcolor: platformCost === 15000 ? 'rgba(41,67,78,0.15)' : 'transparent',
                                                    transition: 'all 0.2s',
                                                    '&:hover': {
                                                        borderColor: '#29434e',
                                                        bgcolor: 'rgba(41,67,78,0.22)'
                                                    }
                                                }}
                                                onClick={() => setPlatformCost(15000)}
                                            >
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                                    <Typography variant="subtitle2">Less than 100</Typography>
                                                    <Box
                                                        sx={{
                                                            width: 20,
                                                            height: 20,
                                                            borderRadius: '50%',
                                                            border: '2px solid',
                                                            borderColor: platformCost === 15000 ? '#29434e' : 'grey.400',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        {platformCost === 15000 && (
                                                            <Box
                                                                sx={{
                                                                    width: 12,
                                                                    height: 12,
                                                                    borderRadius: '50%',
                                                                    bgcolor: '#29434e'
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Typography variant="body2" color="text.secondary">Basic plan</Typography>
                                                <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold' }}>Starting at CHF 15'000</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <Box
                                                sx={{
                                                    border: '1px solid',
                                                    borderColor: platformCost === 60000 ? '#29434e' : 'grey.300',
                                                    borderRadius: 1,
                                                    p: 2,
                                                    cursor: 'pointer',
                                                    bgcolor: platformCost === 60000 ? 'rgba(41,67,78,0.15)' : 'transparent',
                                                    transition: 'all 0.2s',
                                                    '&:hover': {
                                                        borderColor: '#29434e',
                                                        bgcolor: 'rgba(41,67,78,0.22)'
                                                    }
                                                }}
                                                onClick={() => setPlatformCost(60000)}
                                            >
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                                    <Typography variant="subtitle2">Less than 500</Typography>
                                                    <Box
                                                        sx={{
                                                            width: 20,
                                                            height: 20,
                                                            borderRadius: '50%',
                                                            border: '2px solid',
                                                            borderColor: platformCost === 60000 ? '#29434e' : 'grey.400',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        {platformCost === 60000 && (
                                                            <Box
                                                                sx={{
                                                                    width: 12,
                                                                    height: 12,
                                                                    borderRadius: '50%',
                                                                    bgcolor: '#29434e'
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Typography variant="body2" color="text.secondary">Professional plan</Typography>
                                                <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold' }}>Starting at CHF 60'000</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <Box
                                                sx={{
                                                    border: '1px solid',
                                                    borderColor: platformCost === 100000 ? '#29434e' : 'grey.300',
                                                    borderRadius: 1,
                                                    p: 2,
                                                    cursor: 'pointer',
                                                    bgcolor: platformCost === 100000 ? 'rgba(41,67,78,0.15)' : 'transparent',
                                                    transition: 'all 0.2s',
                                                    '&:hover': {
                                                        borderColor: '#29434e',
                                                        bgcolor: 'rgba(41,67,78,0.22)'
                                                    }
                                                }}
                                                onClick={() => setPlatformCost(100000)}
                                            >
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                                    <Typography variant="subtitle2">500+</Typography>
                                                    <Box
                                                        sx={{
                                                            width: 20,
                                                            height: 20,
                                                            borderRadius: '50%',
                                                            border: '2px solid',
                                                            borderColor: platformCost === 100000 ? '#29434e' : 'grey.400',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        {platformCost === 100000 && (
                                                            <Box
                                                                sx={{
                                                                    width: 12,
                                                                    height: 12,
                                                                    borderRadius: '50%',
                                                                    bgcolor: '#29434e'
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Typography variant="body2" color="text.secondary">Enterprise plan</Typography>
                                                <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold' }}>Starting at CHF 100'000</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                        Estimated annual platform cost: <b>{platformCost.toLocaleString('de-CH')} CHF</b>
                                    </Typography>

                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1, fontStyle: 'italic' }}>
                                        Note: Prices shown are starting points. Final costs may vary based on additional requirements,
                                        integrations, customizations, and implementation services needed for your specific environment.
                                        Additional features and premium options are available.
                                    </Typography>

                                    <Button
                                        component={Link}
                                        to="/pricing"
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            mt: 2,
                                            backgroundColor: '#29434e',
                                            '&:hover': {
                                                backgroundColor: '#455a64',
                                            }
                                        }}
                                    >
                                        View Full Pricing Details
                                    </Button>
                                </Box>
                            </Paper>
                        </Grid>

                        {/* Results Section - Fixed sizing */}
                        <Grid item xs={12} md={5}>
                            <Paper sx={{
                                p: 3,
                                backgroundColor: 'rgba(41,67,78,0.09)',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                maxHeight: { xs: 'auto', md: '700px' }, // Control max height
                                overflow: 'auto' // Add scroll if needed
                            }}>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
                                    Your Potential ROI
                                </Typography>

                                <Box sx={{ mb: 4 }}>
                                    <TableContainer component={Paper} elevation={0} sx={{ mb: 3 }}>
                                        <Table size="small">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>Expected Annual Loss (Current)</TableCell>
                                                    <TableCell align="right">{formatCurrency(roiMetrics.totalRiskBefore)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>Expected Annual Loss (With Albatrisk)</TableCell>
                                                    <TableCell align="right">{formatCurrency(roiMetrics.totalRiskAfter)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>Annual Savings</TableCell>
                                                    <TableCell align="right">{formatCurrency(roiMetrics.annualSavings)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>Annual Platform Cost</TableCell>
                                                    <TableCell align="right">{formatCurrency(platformCost)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>Net Value (Savings - Cost)</TableCell>
                                                    <TableCell align="right">{formatCurrency(roiMetrics.roiAmount)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <Box sx={{
                                        bgcolor: '#29434e',
                                        color: 'white',
                                        p: 2,
                                        borderRadius: 2,
                                        textAlign: 'center',
                                        boxShadow: '0 4px 8px rgba(103,58,183,0.2)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 0 }}>
                                            Your Annual ROI
                                        </Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: { xs: 'column', sm: 'row' },
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <Typography variant="h3" sx={{
                                                fontWeight: 'bold',
                                                fontSize: { xs: '2.5rem', sm: '3rem' }
                                            }}>
                                                {roiMetrics.roi.toFixed(0)}%
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                ml: { xs: 0, sm: 2 },
                                                mt: { xs: 1, sm: 0 }
                                            }}>
                                                ({formatCurrency(roiMetrics.roiAmount)})
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Typography variant="body2" paragraph sx={{ fontSize: '0.9rem' }}>
                                    This shows your expected return on investment based on the parameters you've entered.
                                    Albatrisk's risk reduction capabilities can significantly reduce your exposure to costly
                                    risk events while providing a strong return on your technology investment.
                                </Typography>

                            </Paper>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ mt: 8, textAlign: 'center', mb: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Ready to see how Albatrisk can help your business?
                    </Typography>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{
                            mt: 2,
                            backgroundColor: '#29434e',
                            '&:hover': {
                                backgroundColor: '#455a64',
                            }
                        }}
                        component="a"
                        href="https://valbilon.notion.site/1b180f74b39280d6b787e3e74e4c9999?pvs=105"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Schedule a Consultation
                    </Button>
                </Box>
            </Container>
        </Box>
    );
}
// Layout.tsx
import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

export default function Layout() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
        }}>
            <Navbar />
            <Box sx={{
                flexGrow: 1,
                // Navbar height exactly matches the app bar without extra padding
                marginTop: { xs: '56px', sm: '64px' },
                // Remove any padding that might create a gap
                padding: 0
            }}>
                <Outlet />
            </Box>
            <Footer />
        </Box>
    );
}
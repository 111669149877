import React from 'react';
import { Box, Container, Grid, Typography, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import albatriskIcon from '../../assets/images/albatrisk-icon.png';

export default function Footer() {
    return (
        <Box sx={{ bgcolor: '#37474f', color: 'white', py: 4 }}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <img
                                src={albatriskIcon}
                                alt="Albatrisk"
                                style={{ marginRight: '8px', width: '24px', height: '24px' }}
                            />
                            <Typography variant="h6">Albatrisk</Typography>
                        </Box>
                        <Typography variant="body2" sx={{ opacity: 0.7 }}>
                            Empowering businesses with proactive risk management tools that prevent information concealment.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" gutterBottom>Navigation</Typography>
                        <Grid container>
                            {[
                                { name: "Home", path: "/" },
                                { name: "About Us", path: "/about-us" },
                                { name: "Use Cases", path: "/use-case" },
                                { name: "Calculate ROI", path: "/calculate-roi" },
                                { name: "Login", path: "/login" }
                            ].map((link, index) => (
                                <Grid item xs={6} key={index}>
                                    <RouterLink
                                        to={link.path}
                                        style={{
                                            textDecoration: 'none',
                                            display: 'block',
                                            marginBottom: '8px'
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            component="span"
                                            sx={{
                                                color: 'white',
                                                opacity: 0.7,
                                                '&:hover': {
                                                    opacity: 1
                                                }
                                            }}
                                        >
                                            {link.name}
                                        </Typography>
                                    </RouterLink>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" gutterBottom>Legal</Typography>
                        <Grid container>
                            {[
                                { name: "Privacy Policy", path: "/legal?tab=privacy" },
                                { name: "Terms of Service", path: "/legal?tab=terms" },
                                { name: "Disclaimer", path: "/legal?tab=disclaimer" },
                                { name: "Contact Us", path: "/contact" }
                            ].map((link, index) => (
                                <Grid item xs={12} key={index}>
                                    <RouterLink
                                        to={link.path}
                                        style={{
                                            textDecoration: 'none',
                                            display: 'block',
                                            marginBottom: '8px'
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            component="span"
                                            sx={{
                                                color: 'white',
                                                opacity: 0.7,
                                                '&:hover': {
                                                    opacity: 1
                                                }
                                            }}
                                        >
                                            {link.name}
                                        </Typography>
                                    </RouterLink>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 4, bgcolor: 'rgba(255,255,255,0.1)' }} />
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                        © 2025 Albatrisk. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
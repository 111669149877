import React, { useState } from 'react';
import {
    Container, Typography, Grid, Box, Card, CardContent, Button,
    Modal, IconButton, Paper, Divider
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Define TypeScript interfaces
interface Quote {
    text: string;
    attribution: string;
}

interface Section {
    heading: string;
    content: string;
    bulletPoints?: string[];
    additionalContent?: string;
    results?: string[];
    quote?: Quote;
}

interface CaseStudy {
    title: string;
    sections: Section[];
}

interface UseCase {
    title: string;
    description: string;
    fullCaseStudy: CaseStudy;
}

const useCases: UseCase[] = [
    {
        title: "Supply Chain Risk Mitigation",
        description: "A manufacturing company used our platform to identify high-risk suppliers who were concealing financial difficulties, allowing them to diversify their supply chain before disruptions occurred.",
        fullCaseStudy: {
            title: "Supply Chain Risk Mitigation: Global Manufacturing Leader",
            sections: [
                {
                    heading: "Challenge",
                    content: "A Fortune 500 manufacturing company with operations in 12 countries was blindsided when a key component supplier suddenly declared bankruptcy in 2023, causing a 3-week production delay and $4.2M in lost revenue."
                },
                {
                    heading: "Solution Implementation",
                    content: "The company implemented Albatrisk's Supplier Risk Assessment module across their global supply chain of 230+ vendors.",
                    bulletPoints: [
                        "Deploying our proprietary financial stability algorithm to analyze supplier financial data",
                        "Setting up real-time monitoring of supplier risk scores with customized thresholds",
                        "Implementing our early warning system for detecting inconsistencies in supplier reporting"
                    ]
                },
                {
                    heading: "Results",
                    content: "Within 60 days of implementation, Albatrisk identified three high-risk suppliers that were concealing financial difficulties:",
                    bulletPoints: [
                        "Two suppliers showing patterns of delayed payments to their own suppliers",
                        "One supplier with concerning patterns in financial reporting that indicated potential cash flow issues"
                    ],
                    additionalContent: "The company proactively engaged with these suppliers and diversified their supply chain by onboarding alternative vendors. When one of these suppliers indeed faced a significant financial crisis six months later, production continued without interruption.",
                    results: [
                        "94% reduction in supply chain disruptions",
                        "Estimated savings of $5.8M in potential lost revenue",
                        "27% reduction in overall supply chain risk profile"
                    ]
                }
            ]
        }
    },
    {
        title: "Customer Portfolio Management",
        description: "A financial services firm implemented our heat mapping to identify at-risk customers, reducing bad debt by 27% through early intervention.",
        fullCaseStudy: {
            title: "Customer Portfolio Risk Management: National Financial Services Provider",
            sections: [
                {
                    heading: "Challenge",
                    content: "A national financial services provider was experiencing increasing default rates across their small business loan portfolio, with bad debt growing at 15% year-over-year despite traditional credit monitoring tools in place."
                },
                {
                    heading: "Solution Implementation",
                    content: "The company integrated Albatrisk's Customer Portfolio Risk Management solution to provide deeper insights into their 4,500+ small business customers.",
                    bulletPoints: [
                        "Deploying our dynamic heat map visualization across their customer base",
                        "Implementing our proprietary risk scoring algorithm that combines traditional credit data with alternative data sources",
                        "Setting up automated early intervention workflows triggered by risk threshold crossings",
                        "Training customer relationship managers on interpreting risk indicators and conducting proactive outreach"
                    ]
                },
                {
                    heading: "Results",
                    content: "Within the first 90 days, the financial services firm:",
                    bulletPoints: [
                        "Identified 78 high-risk customers that traditional methods had categorized as medium or low risk",
                        "Developed customized intervention plans for each high-risk customer",
                        "Successfully restructured payment terms for 85% of these accounts"
                    ],
                    additionalContent: "One year after implementation:",
                    results: [
                        "Bad debt reduction of 27% compared to the previous year",
                        "Customer retention improvement of 12% due to proactive engagement",
                        "ROI of 315% on the Albatrisk implementation",
                        "Early intervention success rate of 72% with at-risk accounts"
                    ],
                    quote: {
                        text: "Albatrisk's solution transformed our approach from reactive to proactive risk management, fundamentally changing how we maintain portfolio health.",
                        attribution: "Chief Risk Officer"
                    }
                }
            ]
        }
    },
    {
        title: "Product Risk Assessment",
        description: "A healthcare provider used our product risk annotation features to identify compliance issues before they led to regulatory penalties.",
        fullCaseStudy: {
            title: "Product Risk Assessment: Leading Healthcare Solutions Provider",
            sections: [
                {
                    heading: "Challenge",
                    content: "A healthcare technology provider with over 200 medical products was struggling to keep pace with evolving regulatory requirements across multiple international markets. In the previous year, they had incurred $1.2M in regulatory penalties and faced one product recall due to compliance issues that weren't identified early enough."
                },
                {
                    heading: "Solution Implementation",
                    content: "The company implemented Albatrisk's Product Risk Assessment platform with a focus on regulatory compliance.",
                    bulletPoints: [
                        "Setting up our real-time regulatory requirement tracking across 8 international markets",
                        "Implementing our product risk annotation system to flag potential compliance issues",
                        "Deploying our automated documentation validation to ensure all submissions met current standards",
                        "Integrating compliance workflows with their product development lifecycle"
                    ]
                },
                {
                    heading: "Results",
                    content: "Within six months of implementation, the healthcare provider:",
                    bulletPoints: [
                        "Identified 37 potential compliance issues across their product portfolio that required remediation",
                        "Streamlined their regulatory submission process, reducing preparation time by 42%",
                        "Successfully addressed all identified issues before any regulatory penalties occurred"
                    ],
                    additionalContent: "One year after implementation:",
                    results: [
                        "Zero regulatory penalties incurred (down from $1.2M the previous year)",
                        "100% successful rate for new product submissions in all markets",
                        "Compliance documentation preparation time reduced by 62%",
                        "Three potentially serious compliance issues identified and resolved before products reached market"
                    ],
                    quote: {
                        text: "Albatrisk's system not only prevented costly penalties but also accelerated our time-to-market by giving us confidence in our compliance status from day one of development.",
                        attribution: "VP of Regulatory Affairs"
                    }
                }
            ]
        }
    }
];

export default function UseCasesTab() {
    const [openModal, setOpenModal] = useState(-1);

    const handleOpen = (index: number) => {
        setOpenModal(index);
    };

    const handleClose = () => {
        setOpenModal(-1);
    };

    // Original useEffect code remains unchanged
    React.useEffect(() => {
        // Type-safe way to access document elements
        const htmlElement = document.documentElement as HTMLElement;
        const bodyElement = document.body as HTMLElement;
        const rootElement = document.getElementById('root') as HTMLElement | null;

        // Array to store original styles for cleanup
        interface StoredStyle {
            element: HTMLElement;
            cssText: string;
        }

        const originalStyles: StoredStyle[] = [];

        // Store and modify HTML element
        if (htmlElement) {
            originalStyles.push({
                element: htmlElement,
                cssText: htmlElement.style.cssText
            });
            htmlElement.style.height = 'auto';
            htmlElement.style.minHeight = '100%';
            htmlElement.style.overflow = 'visible';
        }

        // Store and modify body element
        if (bodyElement) {
            originalStyles.push({
                element: bodyElement,
                cssText: bodyElement.style.cssText
            });
            bodyElement.style.height = 'auto';
            bodyElement.style.minHeight = '100%';
            bodyElement.style.overflow = 'visible';
        }

        // Store and modify root element
        if (rootElement) {
            originalStyles.push({
                element: rootElement,
                cssText: rootElement.style.cssText
            });
            rootElement.style.height = 'auto';
            rootElement.style.minHeight = '100%';
            rootElement.style.overflow = 'visible';
        }

        return () => {
            // Restore original styles
            originalStyles.forEach(item => {
                item.element.style.cssText = item.cssText;
            });
        };
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                overflow: 'visible',
                pb: 8 // Add padding at bottom
            }}
        >
            <Container maxWidth="lg" sx={{ pt: 6, overflow: 'visible' }}>
                <Typography variant="h3" component="h1" align="center" gutterBottom sx={{ fontWeight: 'bold', mb: 6 }}>
                    How Businesses Use Albatrisk
                </Typography>

                <Grid container spacing={4}>
                    {useCases.map((useCase, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Card sx={{
                                height: '100%',
                                backgroundColor: 'rgba(41,67,78,0.09)',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 3 }}>
                                        {index === 0 && <TuneIcon fontSize="large" sx={{ color: '#29434e', mr: 2 }} />}
                                        {index === 1 && <DashboardIcon fontSize="large" sx={{ color: '#29434e', mr: 2 }} />}
                                        {index === 2 && <WarningIcon fontSize="large" sx={{ color: '#29434e', mr: 2 }} />}
                                        <Typography variant="h5" component="h3" sx={{ fontWeight: 'bold' }}>
                                            {useCase.title}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1" paragraph>
                                        {useCase.description}
                                    </Typography>
                                    <Box sx={{ mt: 'auto', pt: 2 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpen(index)}
                                            sx={{
                                                ml: 2,
                                                backgroundColor: 'white',
                                                color: '#29434e',
                                                '&:hover': {
                                                    backgroundColor: '#29434e',
                                                    color: '#fff',
                                                }
                                            }}
                                        >
                                            Read Full Case Study
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {/* Case Study Modals */}
                {useCases.map((useCase, index) => (
                    <Modal
                        key={index}
                        open={openModal === index}
                        onClose={handleClose}
                        aria-labelledby={`case-study-modal-${index}`}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 2
                        }}
                    >
                        <Paper sx={{
                            maxWidth: 800,
                            maxHeight: '90vh',
                            width: '100%',
                            position: 'relative',
                            overflow: 'auto',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            borderRadius: 2
                        }}>
                            {/* Header with background color */}
                            <Box sx={{
                                p: 3,
                                backgroundColor: '#29434e',
                                color: 'white',
                                position: 'relative'
                            }}>
                                <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', pr: 4 }}>
                                    {useCase.fullCaseStudy.title}
                                </Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: 'white'
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            {/* Content Area */}
                            <Box sx={{ p: 4 }}>
                                {useCase.fullCaseStudy.sections.map((section, sectionIndex) => (
                                    <Box key={sectionIndex} sx={{ mb: 4 }}>
                                        <Typography variant="h5" sx={{ color: '#29434e', fontWeight: 'bold', mb: 2 }}>
                                            {section.heading}
                                        </Typography>

                                        <Typography variant="body1" paragraph>
                                            {section.content}
                                        </Typography>

                                        {section.bulletPoints && (
                                            <Box sx={{ ml: 2, mb: 2 }}>
                                                {section.bulletPoints.map((point, pointIndex) => (
                                                    <Box key={pointIndex} sx={{ display: 'flex', mb: 1.5 }}>
                                                        <Box component="span" sx={{
                                                            mr: 1.5,
                                                            color: '#29434e',
                                                            fontWeight: 'bold',
                                                            fontSize: '1.2rem'
                                                        }}>•</Box>
                                                        <Typography variant="body1">{point}</Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}

                                        {section.additionalContent && (
                                            <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                                                {section.additionalContent}
                                            </Typography>
                                        )}

                                        {section.results && (
                                            <Box sx={{
                                                mt: 3,
                                                p: 2.5,
                                                bgcolor: 'rgba(41,67,78,0.09)',
                                                borderRadius: 2,
                                                borderLeft: '4px solid #29434e'
                                            }}>
                                                <Typography variant="h6" sx={{ mb: 1.5, fontWeight: 'bold' }}>
                                                    Key Results:
                                                </Typography>
                                                {section.results.map((result, resultIndex) => (
                                                    <Box key={resultIndex} sx={{ display: 'flex', mb: 1 }}>
                                                        <Box component="span" sx={{
                                                            mr: 1.5,
                                                            color: '#29434e',
                                                            fontWeight: 'bold'
                                                        }}>✓</Box>
                                                        <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                                                            {result}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}

                                        {section.quote && (
                                            <Box sx={{
                                                mt: 3,
                                                p: 3,
                                                bgcolor: '#f8f8f8',
                                                borderRadius: 2,
                                                borderLeft: '4px solid #29434e',
                                                fontStyle: 'italic'
                                            }}>
                                                <Typography variant="body1" sx={{ mb: 1 }}>
                                                    "{section.quote.text}"
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                    — {section.quote.attribution}
                                                </Typography>
                                            </Box>
                                        )}

                                        {sectionIndex < useCase.fullCaseStudy.sections.length - 1 && (
                                            <Divider sx={{ mt: 4 }} />
                                        )}
                                    </Box>
                                ))}
                            </Box>

                        </Paper>
                    </Modal>
                ))}

                <Box sx={{ mt: 8, p: 4,  backgroundColor: 'rgba(41,67,78,0.09)', borderRadius: 2 }}>
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Industries We Serve
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {[
                            "Manufacturing", "Financial Services", "Healthcare",
                            "Retail", "Logistics", "Technology", "Energy", "Telecommunications"
                        ].map((industry, index) => (
                            <Grid item xs={6} sm={3} key={index}>
                                <Box sx={{
                                    p: 2,
                                    bgcolor: 'white',
                                    borderRadius: 1,
                                    textAlign: 'center',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                    transition: 'transform 0.2s',
                                    '&:hover': { transform: 'scale(1.05)' }
                                }}>
                                    <Typography variant="body1">
                                        {industry}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}
import React from 'react';
import {
    Container,
    Typography,
    Box,
    Grid,
    Card,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import StorageIcon from '@mui/icons-material/Storage';
import InfoIcon from '@mui/icons-material/Info';

export default function PricingPage() {
    // Scrolling fix (kept from original code)
    React.useEffect(() => {
        const htmlElement = document.documentElement;
        const bodyElement = document.body;

        const originalHtmlOverflow = htmlElement.style.overflow;
        const originalBodyOverflow = bodyElement.style.overflow;

        htmlElement.style.overflow = 'auto';
        bodyElement.style.overflow = 'auto';

        return () => {
            htmlElement.style.overflow = originalHtmlOverflow;
            bodyElement.style.overflow = originalBodyOverflow;
        };
    }, []);

    // Features for each pricing tier
    // Features for each pricing tier
    const basicFeatures = [
        'SaaS',
        'Up to 100 suppliers',
        'Standard risk assessments',
        'Automated monthly reports',
        'Basic alerts system',
        'Basic support'
    ];

    const standardFeatures = [
        'All from Basic',
        'SaaS or On-Site',
        'Up to 500 suppliers',
        'Enhanced risk analysis',
        'Historical trend analysis',
        'AI-driven alerts',
        'Integration with company risk frameworks',
        'Dedicated supplier portal for data collection',
        'High-priority support'
    ];

    const enterpriseFeatures = [
        'SaaS or On-Site',
        'All from Standard',
        '1000+ suppliers (pricing scales with supplier count)',
        'Real-time monitoring',
        'Premium data analytics',
        'Predictive modeling',
        'Managed survey campaigns',
        'Dedicated account manager'
    ];

    return (
        <Box sx={{ mt: 6, mb: 8 }}>
            <Container>
                <Typography variant="h3" component="h1" align="center" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                    Pricing Structure
                </Typography>

                <Typography variant="body1" align="center" sx={{ mb: 6, maxWidth: '800px', mx: 'auto' }}>
                    Our pricing is customized to each client's specific needs. The information below outlines our
                    general pricing structure as a starting point for discussions.
                </Typography>

                {/* Base Subscription Tiers Card */}
                <Paper
                    elevation={2}
                    sx={{
                        p: 4,
                        mb: 5,
                        borderRadius: '8px',
                        border: '1px solid #e0e0e0'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <MonetizationOnIcon sx={{ mr: 2, color: '#29434e', fontSize: 28 }} />
                        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                            Base Subscription Tiers (Recurring Fees)
                        </Typography>
                    </Box>

                    <Typography variant="body1" paragraph>
                        Our subscription model is based on the size and complexity of the company's risk exposure:
                    </Typography>

                    <Grid container spacing={4} sx={{ mt: 2 }}>
                        {/* Basic Tier */}
                        <Grid item xs={12} md={4}>
                            <Card sx={{ height: '100%', bgcolor: '#f5f5f5', border: '1px solid #e0e0e0' }}>
                                <CardContent>
                                    <Typography variant="h6" fontWeight="bold" gutterBottom color="#29434e">
                                        Small Corporation
                                    </Typography>
                                    <Typography variant="h5" color="#29434e" gutterBottom>
                                        starting at 15'000/year
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        or 1'500/month
                                    </Typography>

                                    <Divider sx={{ my: 2 }} />

                                    <List disablePadding>
                                        {basicFeatures.map((feature, index) => (
                                            <ListItem key={index} sx={{ py: 0.5, px: 0 }}>
                                                <ListItemIcon sx={{ minWidth: 36 }}>
                                                    <CheckCircleIcon sx={{ color: '#29434e' }} fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText primary={feature} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Standard Tier */}
                        <Grid item xs={12} md={4}>
                            <Card sx={{ height: '100%', bgcolor: '#f5f5f5', border: '1px solid #e0e0e0' }}>
                                <CardContent>
                                    <Typography variant="h6" fontWeight="bold" gutterBottom color="#29434e">
                                        Medium Corporation
                                    </Typography>
                                    <Typography variant="h5" color="#29434e" gutterBottom>
                                        starting at 60'000/year
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        or 6'000/month
                                    </Typography>

                                    <Divider sx={{ my: 2 }} />

                                    <List disablePadding>
                                        {standardFeatures.map((feature, index) => (
                                            <ListItem key={index} sx={{ py: 0.5, px: 0 }}>
                                                <ListItemIcon sx={{ minWidth: 36 }}>
                                                    <CheckCircleIcon sx={{ color: '#29434e' }} fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText primary={feature} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Enterprise Tier */}
                        <Grid item xs={12} md={4}>
                            <Card sx={{ height: '100%', bgcolor: '#f5f5f5', border: '1px solid #e0e0e0' }}>
                                <CardContent>
                                    <Typography variant="h6" fontWeight="bold" gutterBottom color="#29434e">
                                        Large Corporation
                                    </Typography>
                                    <Typography variant="h5" color="#29434e" gutterBottom>
                                        100,000+
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        Custom pricing based on supplier count
                                    </Typography>

                                    <Divider sx={{ my: 2 }} />

                                    <List disablePadding>
                                        {enterpriseFeatures.map((feature, index) => (
                                            <ListItem key={index} sx={{ py: 0.5, px: 0 }}>
                                                <ListItemIcon sx={{ minWidth: 36 }}>
                                                    <CheckCircleIcon sx={{ color: '#29434e' }} fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText primary={feature} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Custom Feature Development Card */}
                <Paper
                    elevation={2}
                    sx={{
                        p: 4,
                        mb: 5,
                        borderRadius: '8px',
                        border: '1px solid #e0e0e0'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <SettingsIcon sx={{ mr: 2, color: '#29434e', fontSize: 28 }} />
                        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                            Custom Feature Development
                        </Typography>
                    </Box>

                    <Typography variant="body1" paragraph>
                        If you need special and custom integration, added features, whitelabel solution and any other special development.
                        The price range depends on the complexity of the features.
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ height: '100%', bgcolor: '#f5f5f5', border: '1px solid #e0e0e0' }}>
                                <CardContent>
                                    <Typography variant="h6" fontWeight="bold" color="#29434e">
                                        Advanced API Integration
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 1 }}>
                                        25,000 - 75,000
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card sx={{ height: '100%', bgcolor: '#f5f5f5', border: '1px solid #e0e0e0' }}>
                                <CardContent>
                                    <Typography variant="h6" fontWeight="bold" color="#29434e">
                                        Special Requests and Developments
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 1 }}>
                                        20,000 - 200,000
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card sx={{ height: '100%', bgcolor: '#f5f5f5', border: '1px solid #e0e0e0' }}>
                                <CardContent>
                                    <Typography variant="h6" fontWeight="bold" color="#29434e">
                                        White-Label Solution
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 1 }}>
                                        100,000 - 300,000
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Data Processing & Survey Card */}
                <Paper
                    elevation={2}
                    sx={{
                        p: 4,
                        mb: 5,
                        borderRadius: '8px',
                        border: '1px solid #e0e0e0'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <StorageIcon sx={{ mr: 2, color: '#29434e', fontSize: 28 }} />
                        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                            Custom Data Processing & Survey
                        </Typography>
                    </Box>

                    <Typography variant="body1" paragraph>
                        If you want custom risk metrics, dashboard customizations, or deeper AI-powered analytics.
                        The prices range depends on the complexity of the features.
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ height: '100%', bgcolor: '#f5f5f5', border: '1px solid #e0e0e0' }}>
                                <CardContent>
                                    <Typography variant="h6" fontWeight="bold" color="#29434e">
                                        Custom Surveys
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 1 }}>
                                        25,000 - 75,000 (depending on complexity)
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card sx={{ height: '100%', bgcolor: '#f5f5f5', border: '1px solid #e0e0e0' }}>
                                <CardContent>
                                    <Typography variant="h6" fontWeight="bold" color="#29434e">
                                        Custom Risk Models
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 1 }}>
                                        50,000 - 150,000
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card sx={{ height: '100%', bgcolor: '#f5f5f5', border: '1px solid #e0e0e0' }}>
                                <CardContent>
                                    <Typography variant="h6" fontWeight="bold" color="#29434e">
                                        Special AI-powered analytics
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 1 }}>
                                        50,000 - 150,000
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Information Notice */}
                <Paper
                    elevation={3}
                    sx={{
                        p: 4,
                        textAlign: 'center',
                        mt: 6,
                        bgcolor: '#eaeff1',
                        borderRadius: '8px',
                        border: '1px solid #e0e0e0'
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                        <InfoIcon sx={{ mr: 1, color: '#29434e', fontSize: 28 }} />
                        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', color: '#29434e' }}>
                            Contact Us for More Information
                        </Typography>
                    </Box>

                    <Typography variant="body1" paragraph sx={{ maxWidth: '800px', mx: 'auto', mb: 4 }}>
                        Every business has unique risk management needs. This pricing information is provided as a general
                        guideline. For specific inquiries or to discuss how our solutions can be tailored to your organization,
                        please contact our team.
                    </Typography>

                    <Divider sx={{ mb: 4 }} />

                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            bgcolor: '#29434e',
                            '&:hover': {
                                bgcolor: '#455a64'
                            }
                        }}
                        component="a"
                        href="https://valbilon.notion.site/1b180f74b39280d6b787e3e74e4c9999"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Request Additional Information
                    </Button>
                </Paper>
            </Container>
        </Box>
    );
}
import React, {useEffect, useState} from 'react';
import {
    Container,
    Typography,
    Grid,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Button,
    Divider,
    Card,
    CardContent,
    CardActions,
    Chip,
    Grid as MuiGrid,
    Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BookIcon from '@mui/icons-material/Book';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import {motion} from 'framer-motion';
import albatriskIcon from '../../assets/images/albatrisk-icon.png';
import donttell from '../../assets/images/dont-tell.png';
import critical from '../../assets/images/critical.png';
import averting from '../../assets/images/averting-disaster.png';
import manmade from '../../assets/images/man-made.png';

export default function AboutUsTab() {
    const [expanded, setExpanded] = useState<string | false>(false);

    // Handle accordion expansion
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Scrolling fix
    useEffect(() => {
        const htmlElement = document.documentElement as HTMLElement;
        const bodyElement = document.body as HTMLElement;

        const originalHtmlOverflow = htmlElement.style.overflow;
        const originalBodyOverflow = bodyElement.style.overflow;

        htmlElement.style.overflow = 'auto';
        bodyElement.style.overflow = 'auto';

        return () => {
            htmlElement.style.overflow = originalHtmlOverflow;
            bodyElement.style.overflow = originalBodyOverflow;
        };
    }, []);

    // Team data with expanded bios
    const teamData = [
        {
            name: "Jaufray Sornette",
            title: "Exective Director",
            shortBio: "Software architect and business software analyst.",
            fullBio: [
                "He has worked for government institutions on international scientific projects, for big industrial corporations with highly complex processes, and in the world of finance and compliance.",
                "During his time at NASA Ames, he designed the core architecture of a prototype currently used by insurance companies to help forecast earthquakes.",
                "He understands the scientific, the military, or the industrial needs, and he adapts to the constraints and complexities of any of these environments."
            ]
        },
        {
            name: "Sergej Hajdin",
            title: "Technical Director",
            shortBio: "Lead Developer of compliance technologies in the world of finance, senior software Engineer & software architect.",
            fullBio: [
                "He has managed international development projects for Big Pharma and the e–commerce sector.",
                "Full-stack developer, he has worked as a contractor for many start-ups, developing MVPs.",
                "He has a lot of experience in many different development management practices, enabling him to adapt to the situation, and he has worked with hardware and embedded systems."
            ]
        },
        {
            name: "Didier Sornette",
            title: "Risk Research Director",
            shortBio: "Professor Emeritus of Entrepreneurial Risks at ETH Zurich and dean of the Institute of Risk Analysis, Prediction and Management (Risks-X) at SUSTech.",
            fullBio: [
                "Professor Emeritus of Entrepreneurial Risks at ETH Zurich and dean of the Institute of Risk Analysis, Prediction and Management (Risks-X), at the Southern University of Science and Technology (SUSTech), Shenzhen.",
                "Member of the Swiss Academy of Engineering Sciences and of the Academia Europaea.",
                "Fellow of the American Association for the Advancement of Science.",
                "He uses rigorous data-driven mathematical statistical analyses combined with nonlinear multi-variable dynamical models with positive and negative feedbacks to study the predictability and control of risks, crises and extreme events in complex systems, with applications to all domains of science and practice."
            ]
        },
        {
            name: "Ke Wu",
            title: "Lead Risk Expert",
            shortBio: "Research Assistant Professor at the Institute of Risk Analysis, Prediction and Management (Risks-X), SUSTech, Shenzhen, China.",
            fullBio: [
                "Research Assistant Professor at the Institute of Risk Analysis, Prediction and Management (Risks-X), Southern University of Science and Technology (SUSTech), Shenzhen, China.",
                "Co-founder and Secretary-General, Swiss-Sino Innovation Center.",
                "Board member of the Chinese Association of Finance Professionals in Switzerland (CAFPS) and the Peking University Alumni Association in Switzerland (PKUAACH), former executive president of Association of Chinese Students and Scholars in Zurich (ACSSZ)."
            ]
        }
    ];

    // Restructured publications with year as a separate property
    const publications = [
        {
            title: "Man-made Catastrophes and Risk Information Concealment",
            year: "2016",
            link: "https://link.springer.com/book/10.1007/978-3-319-24301-6",
            store: "Springer",
            coverImage: manmade
        },
        {
            title: "Critical Risks of Different Economic Sectors",
            year: "2019",
            link: "https://link.springer.com/book/10.1007/978-3-030-25034-8",
            store: "Springer",
            coverImage: critical
        },
        {
            title: "Don't Tell the Boss!",
            year: "2022",
            link: "https://link.springer.com/book/10.1007/978-3-031-05206-4",
            store: "Springer",
            coverImage: donttell
        },
        {
            title: "Averting Disaster Before It Strikes",
            year: "2023",
            link: "https://link.springer.com/book/10.1007/978-3-031-30772-0",
            store: "Springer",
            coverImage: averting
        },
    ];

    return (
        <Box sx={{mt: 6, mb: 8}}>
            <Container>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 6}}>
                    <img
                        src={albatriskIcon}
                        alt="Albatrisk"
                        style={{
                            width: '48px',
                            height: '48px',
                            marginRight: '16px',
                            display: 'inline-block',
                            verticalAlign: 'middle'
                        }}
                    />
                    <Typography
                        variant="h3"
                        component="h1"
                        sx={{
                            fontWeight: 'bold',
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            margin: 0,
                            lineHeight: 1,
                        }}
                    >
                        About Albatrisk
                    </Typography>
                </Box>

                {/* Box 1: Mission, Vision, Expertise */}
                <Paper
                    elevation={2}
                    sx={{
                        p: 4,
                        mb: 6,
                        borderRadius: '8px',
                        border: '1px solid #e0e0e0'
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 3}}>
                        <InfoIcon sx={{color: '#455a64', mr: 1}} />
                        <Typography variant="h5" sx={{fontWeight: 'bold', color: '#29434e'}}>
                            Our Company
                        </Typography>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold', color: '#29434e'}}>
                            Our Mission
                        </Typography>
                        <Typography variant="body1" paragraph>
                            At Albatrisk, our mission is to empower businesses by uncovering hidden risks within their
                            networks. We are dedicated to fostering transparency, believing it is essential for
                            informed, resilient, and strategic decision-making.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Our team of risk management experts, data scientists, and industry specialists has developed
                            a platform that reveals concealed information and provides actionable insights to protect
                            your business.
                        </Typography>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold', color: '#29434e'}}>
                            Our Vision
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We envision a world where businesses proactively manage risks, effectively safeguarding
                            themselves from hidden vulnerabilities. By shining a light on concealed information,
                            Albatrisk aims to set new standards in risk management and corporate accountability,
                            ultimately creating safer and more transparent business environments.
                        </Typography>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <Box>
                        <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold', color: '#29434e'}}>
                            Our Expertise
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Our platform is backed by extensive research spanning many years, focusing specifically on
                            catastrophic events resulting from concealed information and poor risk communication. Led by
                            Professor Didier Sornette, our team has produced numerous influential books and academic
                            articles on man-made catastrophes.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            With this deep expertise, we provide businesses with actionable insights, empowering them to
                            mitigate risks and make smarter, safer decisions.
                        </Typography>
                    </Box>
                </Paper>

                {/* Box 2: Leadership Team */}
                <Paper
                    elevation={2}
                    sx={{
                        p: 4,
                        mb: 6,
                        borderRadius: '8px',
                        border: '1px solid #e0e0e0'
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 3}}>
                        <PersonIcon sx={{color: '#455a64', mr: 1}} />
                        <Typography variant="h5" sx={{fontWeight: 'bold', color: '#29434e'}}>
                            Our Leadership Team
                        </Typography>
                    </Box>

                    {teamData.map((person, index) => (
                        <motion.div
                            key={index}
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.1}}
                            style={{marginBottom: index === teamData.length - 1 ? 0 : 16}}
                        >
                            <Accordion
                                expanded={expanded === `panel${index}`}
                                onChange={handleChange(`panel${index}`)}
                                sx={{
                                    boxShadow: 'none',
                                    '&:before': {
                                        display: 'none',
                                    },
                                    backgroundColor: 'transparent',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '8px !important',
                                    overflow: 'hidden'
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{color: '#29434e'}}/>}
                                    aria-controls={`panel${index}bh-content`}
                                    id={`panel${index}bh-header`}
                                    sx={{
                                        bgcolor: expanded === `panel${index}` ? '#eaeff1' : 'white',
                                        transition: 'background-color 0.2s ease'
                                    }}
                                >
                                    <Box>
                                        <Typography variant="h6" sx={{fontWeight: 'bold', color: '#29434e'}}>
                                            {person.name}
                                        </Typography>
                                        <Typography variant="subtitle1" color="#455a64" gutterBottom>
                                            {person.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {person.shortBio}
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{padding: '16px', bgcolor: 'white'}}>
                                    <Divider sx={{mb: 2}} />
                                    <List sx={{pl: 2}}>
                                        {person.fullBio.map((point, i) => (
                                            <ListItem key={i} sx={{
                                                display: 'list-item',
                                                listStyleType: 'disc',
                                                p: 0,
                                                pl: 1,
                                                mb: 1
                                            }}>
                                                <ListItemText
                                                    primary={point}
                                                    primaryTypographyProps={{
                                                        variant: 'body2',
                                                        color: '#455a64'
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        </motion.div>
                    ))}
                </Paper>

                {/* Box 3: Publications */}
                <Paper
                    elevation={2}
                    sx={{
                        p: 4,
                        mb: 4,
                        borderRadius: '8px',
                        border: '1px solid #e0e0e0'
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 3}}>
                        <BookIcon sx={{color: '#455a64', mr: 1}} />
                        <Typography variant="h5" sx={{fontWeight: 'bold', color: '#29434e'}}>
                            Research Publications
                        </Typography>
                    </Box>

                    <Typography variant="body1" paragraph sx={{ mb: 4 }}>
                        Our research forms the foundation of our risk assessment methodologies. These publications document our findings on information concealment and risk management across various sectors.
                    </Typography>

                    <MuiGrid container spacing={3}>
                        {publications.map((pub, index) => (
                            <MuiGrid item xs={12} sm={6} md={3} key={index}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        border: '1px solid #e0e0e0',
                                        borderRadius: '8px',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)'
                                        }
                                    }}
                                >
                                    {/* Book cover image */}
                                    <Box
                                        sx={{
                                            height: 220,
                                            bgcolor: '#eaeff1',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderBottom: '1px solid #e0e0e0',
                                            position: 'relative',
                                            overflow: 'hidden',
                                            padding: '12px'
                                        }}
                                    >
                                        {pub.coverImage ? (
                                            <Box
                                                component="img"
                                                src={pub.coverImage}
                                                alt={`${pub.title} cover`}
                                                sx={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    objectFit: 'contain',
                                                    transition: 'transform 0.3s ease',
                                                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                                    '&:hover': {
                                                        transform: 'scale(1.05)'
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <Box sx={{ textAlign: 'center', p: 2 }}>
                                                <BookIcon sx={{ fontSize: 48, color: '#29434e', opacity: 0.7, mb: 1 }} />
                                                <Typography variant="caption" display="block" color="text.secondary">
                                                    Cover image will be added
                                                </Typography>
                                            </Box>
                                        )}
                                        <Chip
                                            label={pub.year}
                                            size="small"
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                bgcolor: 'rgba(41, 67, 78, 0.85)',
                                                color: 'white',
                                                fontWeight: 'bold'
                                            }}
                                        />
                                    </Box>

                                    <CardContent sx={{
                                        bgcolor: '#f5f5f5',
                                        pb: 1,
                                        flexGrow: 1,
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold', color: '#29434e', flexGrow: 1}}>
                                            {pub.title}
                                        </Typography>
                                    </CardContent>

                                    <CardActions sx={{p: 2, bgcolor: '#f5f5f5', justifyContent: 'center'}}>
                                        <Button
                                            variant="contained"
                                            href={pub.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            fullWidth
                                            sx={{
                                                backgroundColor: '#455a64',
                                                color: 'white',
                                                padding: '8px 16px',
                                                borderRadius: '8px',
                                                boxShadow: '0 4px 12px rgba(69, 90, 100, 0.2)',
                                                transition: 'all 0.3s ease',
                                                '&:hover': {
                                                    backgroundColor: '#29434e',
                                                    transform: 'translateY(-2px)',
                                                    boxShadow: '0 6px 16px rgba(69, 90, 100, 0.3)',
                                                }
                                            }}
                                            startIcon={<ShoppingCartIcon />}
                                        >
                                            Buy on {pub.store}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </MuiGrid>
                        ))}
                    </MuiGrid>
                </Paper>
            </Container>
        </Box>
    );
}
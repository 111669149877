import React, { useEffect } from 'react';
import { Box, Typography, TextField, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useAuthenticate } from '../../api/useAuthenticate';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';
import albatriskIcon from '../../assets/images/albatrisk-icon.png';

// Layout structure
const PageWrapper = styled(Box)({
    minHeight: '70vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f5f5f5'
});

const LoginCard = styled(Box)(({ theme }) => ({
    width: '400px',
    padding: '40px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100vh',
        padding: '32px 24px',
        borderRadius: 0,
        boxShadow: 'none',
        justifyContent: 'center'
    }
}));

const LogoBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px'
});

const FormBox = styled(Box)({
    width: '100%'
});

const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
});

export function Login() {
    const navigate = useNavigate();
    const { loading, execute } = useAuthenticate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Check if user is already logged in
    useEffect(() => {
        const checkAuthStatus = () => {
            const authData = localStorage.getItem("authData");
            if (authData) {
                try {
                    const parsedAuthData = JSON.parse(authData);
                    // Check if the auth data contains a valid authenticated flag
                    if (parsedAuthData && parsedAuthData.authenticated) {
                        // Optionally, you could verify the token validity here
                        // For example, check if it's expired or valid with your backend
                        navigate('/dashboard');
                    }
                } catch (error) {
                    // If JSON parsing fails, clear the invalid auth data
                    console.error("Invalid auth data found:", error);
                    localStorage.removeItem("authData");
                }
            }
        };

        checkAuthStatus();
    }, [navigate]);

    const handleSubmit = async (values: { username: string; password: string; }) => {
        try {
            const authData = await execute(values.username, values.password);
            if (authData.authenticated) {
                localStorage.setItem("authData", JSON.stringify(authData));
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Authentication failed:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    useEffect(() => {
        // Only add minimal fixes to enable scrolling without creating excessive space
        const htmlElement = document.documentElement as HTMLElement;
        const bodyElement = document.body as HTMLElement;

        // Store original overflow values
        const originalHtmlOverflow = htmlElement.style.overflow;
        const originalBodyOverflow = bodyElement.style.overflow;

        // Only set overflow to auto, not changing height properties
        htmlElement.style.overflow = 'auto';
        bodyElement.style.overflow = 'auto';

        return () => {
            // Restore original overflow values
            htmlElement.style.overflow = originalHtmlOverflow;
            bodyElement.style.overflow = originalBodyOverflow;
        };
    }, []);

    return (
        <PageWrapper>
            <LoginCard>
                <LogoBox>
                    <img
                        src={albatriskIcon}
                        alt="Albatrisk"
                        style={{
                            width: isMobile ? '48px' : '64px',
                            height: isMobile ? '48px' : '64px'
                        }}
                    />
                </LogoBox>

                <Typography
                    variant={isMobile ? "h6" : "h5"}
                    component="h1"
                    sx={{
                        fontWeight: 'bold',
                        color: '#29434e',
                        textAlign: 'center',
                        mb: 1
                    }}
                >
                    Albatrisk Platform
                </Typography>

                <Typography
                    variant={isMobile ? "body2" : "subtitle1"}
                    sx={{
                        color: '#37474f',
                        opacity: 0.7,
                        mb: 3,
                        textAlign: 'center'
                    }}
                >
                    Welcome Back
                </Typography>

                <FormBox>
                    <Formik
                        initialValues={{ username: '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form style={{ width: '100%' }}>
                                <Field
                                    as={TextField}
                                    margin="normal"
                                    fullWidth
                                    id="username"
                                    name="username"
                                    label="Username"
                                    error={touched.username && Boolean(errors.username)}
                                    helperText={touched.username && errors.username}
                                    size={isMobile ? "small" : "medium"}
                                    sx={{
                                        mb: 2,
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#37474f',
                                            },
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#37474f',
                                        },
                                    }}
                                />
                                <Field
                                    as={TextField}
                                    margin="normal"
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    size={isMobile ? "small" : "medium"}
                                    sx={{
                                        mb: 3,
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#37474f',
                                            },
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#37474f',
                                        },
                                    }}
                                />
                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        py: isMobile ? 1 : 1.2,
                                        backgroundColor: '#37474f',
                                        '&:hover': {
                                            backgroundColor: '#29434e',
                                        }
                                    }}
                                    loading={loading}
                                    size={isMobile ? "medium" : "large"}
                                >
                                    SIGN IN
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </LoginCard>
        </PageWrapper>
    );
}
// Navbar.tsx
import React, { useState } from 'react';
import {
    Typography, Button, AppBar, Toolbar, Box, IconButton, Drawer, List, ListItem
} from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import albatriskIcon from '../../assets/images/albatrisk-icon.png';

// Update the AppBar to be fixed and remove any margin/padding issues
const StyledAppBar = styled(AppBar)({
    background: '#37474f',
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
    position: 'fixed',
    zIndex: 1100,
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
});

// Define the props interface for NavLink
interface NavLinkProps {
    to: string;
    active: boolean;
    children: React.ReactNode;
}

// Styled nav link with properly typed props and animation
const NavLink = styled(motion.div)({
    display: 'inline-block',
});

// List of navigation items
const navItems = [
    { path: '/', label: 'Home' },
    { path: '/use-case', label: 'Use Cases' },
    { path: '/calculate-roi', label: 'Calculate ROI' },
    { path: '/onboarding', label: 'Onboarding' },
    { path: '/pricing', label: 'Pricing' },
    { path: '/about-us', label: 'About Us' },
    { path: '/contact', label: 'Contact' },
];

// Content wrapper to ensure proper spacing
const ContentWrapper = styled('div')({
    marginTop: '64px', // Height of the navbar
    width: '100%',
    padding: 0,
    '@media (max-width: 600px)': {
        marginTop: '56px', // Mobile height of navbar
    },
});

export default function Navbar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleLoginClick = () => {
        navigate('/login');
    };

    const isActive = (path: string): boolean => {
        return location.pathname === path;
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // Mobile drawer content
    const drawer = (
        <Box sx={{ width: 250, bgcolor: '#37474f', height: '100%', color: 'white' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                <img src={albatriskIcon} alt="Albatrisk" style={{ marginRight: '8px', width: '24px', height: '24px' }} />
                <Typography variant="h6" component="div" color="white">
                    Albatrisk
                </Typography>
            </Box>
            <List>
                {navItems.map((item) => (
                    <ListItem
                        key={item.path}
                        component={Link}
                        to={item.path}
                        sx={{
                            color: 'white',
                            textDecoration: 'none',
                            py: 1.5,
                            borderLeft: isActive(item.path) ? '4px solid white' : 'none',
                            bgcolor: isActive(item.path) ? 'rgba(255,255,255,0.1)' : 'transparent',
                            '&:hover': {
                                bgcolor: 'rgba(255,255,255,0.1)',
                            },
                            pl: isActive(item.path) ? 2 : 3,
                        }}
                        onClick={() => setMobileOpen(false)}
                    >
                        {item.label}
                    </ListItem>
                ))}
            </List>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Button
                    fullWidth
                    variant={'contained'}
                    onClick={handleLoginClick}
                    sx={{
                        backgroundColor: 'white',
                        color: '#29434e',
                        '&:hover': {
                            backgroundColor: '#29434e',
                            color: '#fff',
                        }
                    }}>
                    Login
                </Button>
            </Box>
        </Box>
    );

    return (
        <>
            <StyledAppBar elevation={0}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 400, damping: 10 }}
                        >
                            <img src={albatriskIcon} alt="Albatrisk" style={{ marginRight: '8px', width: '24px', height: '24px' }} />
                        </motion.div>
                        <Typography variant="h6" component="div" color="white">
                            Albatrisk
                        </Typography>
                    </Box>

                    {/* Mobile menu icon */}
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/* Desktop navigation */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {navItems.map((item) => (
                            <NavLink key={item.path}
                                     whileHover={{ y: -2 }}
                                     transition={{ type: "spring", stiffness: 300 }}
                            >
                                <Link
                                    to={item.path}
                                    style={{
                                        color: 'white',
                                        padding: '20px 16px',
                                        textDecoration: 'none',
                                        borderBottom: isActive(item.path) ? '2px solid white' : 'none',
                                        display: 'inline-block',
                                    }}
                                >
                                    {item.label}
                                </Link>
                            </NavLink>
                        ))}
                    </Box>

                    {/* Desktop buttons */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                            <Button
                                variant={'contained'}
                                onClick={handleLoginClick}
                                sx={{
                                    ml: 2,
                                    backgroundColor: 'white',
                                    color: '#29434e',
                                    '&:hover': {
                                        backgroundColor: '#29434e',
                                        color: '#fff',
                                    }
                                }}>
                                LOGIN
                            </Button>
                        </motion.div>
                    </Box>
                </Toolbar>
            </StyledAppBar>

            {/* Mobile drawer */}
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better mobile performance
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
                }}
            >
                {drawer}
            </Drawer>
        </>
    );
}
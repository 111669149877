import React, {useEffect} from 'react';
import {Container, Typography, Grid, Box, Button, TextField, Paper, styled} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

// Custom styled components
const ContactTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#455a64',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#455a64',
        },
    },
    '& .MuiFormLabel-root.Mui-focused': {
        color: '#455a64',
    },
    marginBottom: '16px'
});

export default function ContactTab() {
    // State to store form values
    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        message: ''
    });

    // Handle form input changes
    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const {name, value} = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        // Create email body with form data
        const subject = `Contact Form: ${formData.firstName} ${formData.lastName} from ${formData.company}`;
        const body = `Name: ${formData.firstName} ${formData.lastName}
Email: ${formData.email}
Company: ${formData.company}

Message:
${formData.message}`;

        // Create mailto link
        const mailtoLink = `mailto:info@albatrisk.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        // Open email client
        window.location.href = mailtoLink;
    };

    useEffect(() => {
        // Only add minimal fixes to enable scrolling without creating excessive space
        const htmlElement = document.documentElement as HTMLElement;
        const bodyElement = document.body as HTMLElement;

        // Store original overflow values
        const originalHtmlOverflow = htmlElement.style.overflow;
        const originalBodyOverflow = bodyElement.style.overflow;

        // Only set overflow to auto, not changing height properties
        htmlElement.style.overflow = 'auto';
        bodyElement.style.overflow = 'auto';

        return () => {
            // Restore original overflow values
            htmlElement.style.overflow = originalHtmlOverflow;
            bodyElement.style.overflow = originalBodyOverflow;
        };
    }, []);
    return (
        <Box
            sx={{
                overflow: 'visible',
                backgroundColor: '#f5f7fa',
                minHeight: '80vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
        }}
        >
            <Container maxWidth="lg">
                <Typography
                    variant="h3"
                    component="h1"
                    align="center"
                    gutterBottom
                    sx={{
                        fontWeight: 'bold',
                        mb: 6,
                        color: '#263238',
                        position: 'relative',
                        '&:after': {
                            content: '""',
                            position: 'absolute',
                            width: '80px',
                            height: '4px',
                            backgroundColor: '#455a64',
                            bottom: '-16px',
                            left: 'calc(50% - 40px)',
                        }
                    }}
                >
                    Contact Us
                </Typography>

                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={10} lg={8}>
                        <Paper
                            elevation={3}
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{
                                p: 4,
                                borderRadius: '12px',
                            }}
                        >
                            <Typography variant="h5" gutterBottom sx={{fontWeight: 'bold', color: '#263238', mb: 3}}>
                                Send Us a Message
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <ContactTextField
                                        label="First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ContactTextField
                                        label="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ContactTextField
                                        label="Email Address"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        type="email"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ContactTextField
                                        label="Company"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ContactTextField
                                        label="Message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        endIcon={<SendIcon/>}
                                        sx={{
                                            backgroundColor: '#455a64',
                                            color: 'white',
                                            padding: '12px 24px',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 12px rgba(69, 90, 100, 0.2)',
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                backgroundColor: '#29434e',
                                                transform: 'translateY(-2px)',
                                                boxShadow: '0 6px 16px rgba(69, 90, 100, 0.3)',
                                            }
                                        }}
                                    >
                                        Send Email
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}